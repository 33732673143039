import Box from 'UIKit/Box/Box'
import Flex from 'UIKit/Box/Flex'
import IconButton from 'UIKit/Button/IconButton'
import Modal, { ModalBody, ModalHeader, ModalProps } from 'UIKit/ModalV2'
import Tab from 'UIKit/Tab'
import Tabs from 'UIKit/Tabs'
import Text from 'UIKit/Text'
import TransText from 'UIKit/Text/TransText'
import { RouteConfig } from 'config/constants/route'
import { UsableFiatCurrencies } from 'config/constants/tokens/fiat'
import { Token } from 'config/types'
import useMatchBreakpoints from 'hooks/useMatchBreakpoints'
import { useIsDisabledFeaturedOnTelegram } from 'hooks/useTelegram'
import { RowCenter, RowMiddle } from 'layout/Components/Row'
import { useRouter } from 'next/router'
import { useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDisplayCommitment } from 'state/app/hooks'
import { useProfileBaseTier } from 'state/profile/hooks'
import { useTokenSelected } from 'state/session/hooks'
import styled from 'styled-components'
import { Icons } from 'svgs'
import theme from 'theme'
import base from 'theme/base'
import { colors } from 'theme/colors'
import WarningOnTelegram from 'views/Authentication/components/Wallet/WarningOnTelegram/WarningOnTelegram'
import { DepositOptionEnums, PaymentMenuDesktop, PaymentMenuMoble, PaymentPageEnums, WithdrawOptionEnums } from './Menu'
import Badge2FA from './components/Badge2FA'
import DepositByBuyCrypto from './components/Deposit/DepositByBuyCrypto'
import DepositByFiat from './components/Deposit/DepositByFiat'
import DepositCryptos from './components/Deposit/DepositCryptos'
import PaymentCommitments from './components/PaymentCommitments'
import PaymentCommitmentsBar from './components/PaymentCommitments/PaymentCommitmentsBar'
import Swap from './components/Swap'
import Tip from './components/Tip'
import TipAcceptModal from './components/Tip/components/TipAcceptModal'
import WithdrawByFiat from './components/Withdraw/WithdrawByFiat'
import WithdrawCrypto from './components/Withdraw/WithdrawCryptos'

export const TransactionUrls = {
  [PaymentPageEnums.Deposit]: RouteConfig.DepositTransactionHistory,
  [PaymentPageEnums.Withdraw]: RouteConfig.WithdrawTransactionHistory,
  [PaymentPageEnums.Swap]: RouteConfig.SwapTransactionHistory,
  [PaymentPageEnums.Tip]: RouteConfig.TipTransactionHistory,
}

interface PaymentProps {
  defaultPage?: PaymentPageEnums
  initialToken?: Token
  pageData?: { tipUserName?: string; depositSubTag?: DepositOptionEnums }
}

const TIP_LEVEL_CONDITION = 4

const Payment: React.FC<ModalProps<PaymentProps>> = ({ data: { defaultPage, initialToken, pageData }, onDismiss }) => {
  const selectedToken = useTokenSelected()
  const isCommitmentFromSystemSetting = useDisplayCommitment()

  const isFiatCurrency = UsableFiatCurrencies.includes(selectedToken.code)

  const [paymentPage, setPaymentPage] = useState<PaymentPageEnums>(defaultPage || PaymentPageEnums.Deposit)
  const [depositOption, setDepositOption] = useState<DepositOptionEnums>(
    pageData?.depositSubTag || (isFiatCurrency ? DepositOptionEnums.Fiat : DepositOptionEnums.Crypto),
  )
  const [withdrawOption, setWithdrawOption] = useState<WithdrawOptionEnums>(
    isFiatCurrency ? WithdrawOptionEnums.Fiat : WithdrawOptionEnums.Crypto,
  )
  const [isDisplayCommitments, setIsDisplayCommitments] = useState(false)

  const toggleDisplayCommitments = () => {
    setIsDisplayCommitments(!isDisplayCommitments)
  }

  const router = useRouter()
  const userBaseTier = useProfileBaseTier()
  const isAcceptTip = userBaseTier?.level >= TIP_LEVEL_CONDITION

  const isDisabledFeaturedOnTelegram = useIsDisabledFeaturedOnTelegram()

  const { t } = useTranslation()
  const { isMobile } = useMatchBreakpoints()

  const titlePayment = useMemo(() => {
    if (isMobile) {
      return t('Wallet')
    }

    if (paymentPage === PaymentPageEnums.Deposit) {
      return depositOption === DepositOptionEnums.BuyCrypto ? t('Buy crypto using cards') : t('Deposit')
    }

    if (paymentPage === PaymentPageEnums.Withdraw) return t('Withdraw')

    if (paymentPage === PaymentPageEnums.Swap) return t('Swap')

    if (paymentPage === PaymentPageEnums.Tip) return t('Tip')

    return ''
  }, [paymentPage, depositOption, t, isMobile])

  const handlePageChange = (value: PaymentPageEnums) => {
    if (isDisplayCommitments) setIsDisplayCommitments(false)
    setPaymentPage(value)
  }

  return (
    <StyledModal id="payment" onDismiss={onDismiss}>
      <Flex width="100%" height="100%">
        <PaymentMenuDesktop
          paymentPage={paymentPage}
          depositSubTabOption={depositOption}
          withdrawSubTabOption={withdrawOption}
          onDepositSubTabChanged={setDepositOption}
          onWithdrawSubTabChanged={setWithdrawOption}
          onPaymentPageChanged={handlePageChange}
          width={['100%', '', '200px']}
          height={['auto', '', '100%']}
          minWidth={['100%', '', '200px']}
          display={['none', '', 'block']}
        />

        <Flex flexDirection="column" p={['0 20px']} width={['100%', '', 'calc(100% - 200px)']} height="100%">
          <ModalHeader>
            <Flex alignItems="center">
              {isDisplayCommitments ? (
                <RowMiddle>
                  <StyledBackButtonsTitle onClick={toggleDisplayCommitments}>
                    <Icons.BackIcon width="24px" height="24px" fill={colors.text} />
                  </StyledBackButtonsTitle>
                  <TransText fontSize="1em">HunnyPlay's Commitments</TransText>
                </RowMiddle>
              ) : (
                <Text fontSize="1em">{titlePayment}</Text>
              )}

              {isMobile && !isDisplayCommitments && <Badge2FA />}
            </Flex>
          </ModalHeader>

          {!isDisplayCommitments && (
            <PaymentMenuMoble
              paymentPage={paymentPage}
              onPaymentPageChanged={setPaymentPage}
              display={['block', '', 'none']}
              mb="20px"
            />
          )}

          <StyledContent width="100%" height="100%" overflowY="auto">
            {paymentPage === PaymentPageEnums.Deposit && !isDisabledFeaturedOnTelegram && !isDisplayCommitments && (
              <Box pb="20px" display={['block', '', 'none']}>
                <StyledMobileDepositOptionTabs value={depositOption} onTagChanged={() => {}} p={[3, 3, 0]}>
                  <Tab
                    value={DepositOptionEnums.Crypto}
                    borderRadius={base.radii.tiny}
                    height="100%"
                    width="fit-content"
                  >
                    <Text
                      p="8px 0"
                      fontSize={['14px', '', '16px']}
                      mx="auto"
                      fontWeight={depositOption === DepositOptionEnums.Crypto ? 500 : 300}
                      color={depositOption === DepositOptionEnums.Crypto ? theme.colors.text : theme.colors.textSubtle}
                      textAlign="center"
                      onClick={() => {
                        setDepositOption(DepositOptionEnums.Crypto)
                      }}
                      style={{
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <Trans>Crypto</Trans>
                    </Text>
                  </Tab>

                  <Tab value={DepositOptionEnums.Fiat} width="fit-content" px="14px" borderRadius={base.radii.tiny}>
                    <Text
                      p="8px 0"
                      fontSize={['14px', '', '16px']}
                      mx="auto"
                      fontWeight={depositOption === DepositOptionEnums.Fiat ? 500 : 300}
                      color={depositOption === DepositOptionEnums.Fiat ? theme.colors.text : theme.colors.textSubtle}
                      textAlign="center"
                      onClick={() => {
                        setDepositOption(DepositOptionEnums.Fiat)
                      }}
                      style={{
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <Trans>Fiat</Trans>
                    </Text>
                  </Tab>

                  <Tab value={DepositOptionEnums.BuyCrypto} width="fit-content" borderRadius={base.radii.tiny}>
                    <Text
                      p="8px 0"
                      fontSize={['14px', '', '16px']}
                      mx="auto"
                      fontWeight={depositOption === DepositOptionEnums.BuyCrypto ? 500 : 300}
                      color={
                        depositOption === DepositOptionEnums.BuyCrypto ? theme.colors.text : theme.colors.textSubtle
                      }
                      textAlign="center"
                      onClick={() => {
                        setDepositOption(DepositOptionEnums.BuyCrypto)
                      }}
                      style={{
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <Trans>Buy crypto</Trans>
                    </Text>
                  </Tab>
                </StyledMobileDepositOptionTabs>
              </Box>
            )}

            {paymentPage === PaymentPageEnums.Withdraw && !isDisabledFeaturedOnTelegram && (
              <Box pb="20px" display={['block', '', 'none']}>
                <StyledMobileWithdrawOptionTabs value={withdrawOption} onTagChanged={() => {}} p={[3, 3, 0]}>
                  <Tab
                    value={WithdrawOptionEnums.Crypto}
                    width="fit-content"
                    borderRadius={base.radii.tiny}
                    height="100%"
                  >
                    <Text
                      p="8px 0"
                      fontSize={['14px', '', '16px']}
                      mx="auto"
                      fontWeight={withdrawOption === WithdrawOptionEnums.Crypto ? 500 : 300}
                      color={
                        withdrawOption === WithdrawOptionEnums.Crypto ? theme.colors.text : theme.colors.textSubtle
                      }
                      textAlign="center"
                      onClick={() => {
                        setWithdrawOption(WithdrawOptionEnums.Crypto)
                      }}
                      style={{
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <Trans>Crypto</Trans>
                    </Text>
                  </Tab>

                  <Tab value={WithdrawOptionEnums.Fiat} width="fit-content" px="14px" borderRadius={base.radii.tiny}>
                    <Text
                      p="8px 0"
                      fontSize={['14px', '', '16px']}
                      mx="auto"
                      fontWeight={withdrawOption === WithdrawOptionEnums.Fiat ? 500 : 300}
                      color={withdrawOption === WithdrawOptionEnums.Fiat ? theme.colors.text : theme.colors.textSubtle}
                      textAlign="center"
                      onClick={() => {
                        setWithdrawOption(WithdrawOptionEnums.Fiat)
                      }}
                      style={{
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <Trans>Fiat</Trans>
                    </Text>
                  </Tab>
                </StyledMobileWithdrawOptionTabs>
              </Box>
            )}

            <Box width="100%">
              {paymentPage === PaymentPageEnums.Deposit &&
                (isDisabledFeaturedOnTelegram ? (
                  <WarningOnTelegram />
                ) : (
                  <>
                    {!isDisplayCommitments && isCommitmentFromSystemSetting && (
                      <PaymentCommitmentsBar mb="20px" handleClick={toggleDisplayCommitments} />
                    )}

                    {isDisplayCommitments ? (
                      <PaymentCommitments />
                    ) : (
                      <>
                        {depositOption === DepositOptionEnums.Crypto && <DepositCryptos initialToken={initialToken} />}
                        {depositOption === DepositOptionEnums.Fiat && <DepositByFiat />}
                        {depositOption === DepositOptionEnums.BuyCrypto && <DepositByBuyCrypto />}
                      </>
                    )}
                  </>
                ))}

              {paymentPage === PaymentPageEnums.Withdraw &&
                (isDisabledFeaturedOnTelegram ? (
                  <WarningOnTelegram />
                ) : (
                  <>
                    {withdrawOption === WithdrawOptionEnums.Crypto && (
                      <WithdrawCrypto initialToken={initialToken} onDismiss={onDismiss} />
                    )}
                    {withdrawOption === WithdrawOptionEnums.Fiat && <WithdrawByFiat onDismiss={onDismiss} />}
                  </>
                ))}

              {paymentPage === PaymentPageEnums.Swap && <Swap />}
              {paymentPage === PaymentPageEnums.Tip &&
                (isDisabledFeaturedOnTelegram ? (
                  <WarningOnTelegram />
                ) : (
                  <Box position={['relative', 'relative', 'unset']}>
                    <Tip
                      initialToken={initialToken}
                      userName={pageData?.tipUserName || ''}
                      isAcceptTip={isAcceptTip}
                      onDismiss={onDismiss}
                    />
                    {!isAcceptTip && <TipAcceptModal />}
                  </Box>
                ))}
            </Box>

            {!isDisabledFeaturedOnTelegram && !isDisplayCommitments && (
              <RowCenter
                mt={
                  paymentPage === PaymentPageEnums.Deposit && depositOption === DepositOptionEnums.Crypto
                    ? '12px'
                    : '24px'
                }
                justifyContent="flex-end"
                alignItems="center"
                onClick={() => {
                  router.push(TransactionUrls[paymentPage])
                  onDismiss()
                }}
                style={{
                  cursor: 'pointer',
                }}
              >
                <Text lineHeight="1.1" color="primaryAlt" small>
                  <Trans>Transaction History</Trans>
                </Text>
              </RowCenter>
            )}
          </StyledContent>
        </Flex>
      </Flex>
    </StyledModal>
  )
}

const StyledModal = styled(Modal)`
  padding: 0;

  box-sizing: border-box;

  background: ${({ theme: { colors } }) => colors.modalBackground};

  overflow-y: hidden;
  border-top-left-radius: ${({ theme }) => theme.radii.large};
  border-top-right-radius: ${({ theme }) => theme.radii.large};

  height: max(calc(var(--screen-height) - 20vh), min(820px, calc(var(--screen-height) - 40px)));

  & .modal-closebutton {
    right: 12px;
    top: 12px;
  }

  ${({ theme: { mediaQueries } }) => mediaQueries.sm} {
    min-width: 600px;
    max-width: 600px;
    border-radius: ${({ theme }) => theme.radii.large};
    height: max(calc(var(--screen-height) - 20vh), min(760px, calc(var(--screen-height) - 40px)));
  }
`

const StyledMobileDepositOptionTabs = styled(Tabs)`
  padding: 0;
  max-width: 100%;
`
const StyledMobileWithdrawOptionTabs = StyledMobileDepositOptionTabs

const StyledContent = styled(ModalBody)`
  box-sizing: border-box;
  width: calc(100% + 40px);
  padding-inline: 20px;
  margin-inline: -20px;
  padding-bottom: 20px;
`

const StyledBackButtonsTitle = styled(IconButton)`
  margin-right: 2px;
  svg {
    fill: ${({ theme }) => theme.colors.text} !important;
  }
`

export default Payment
