import BigNumber from 'bignumber.js'
import { BIG_ZERO } from 'config/constants/number'
import { Token } from 'config/types'
import { RowMiddle } from 'layout/Components/Row'
import { Trans } from 'react-i18next'
import styled from 'styled-components'
import Text from 'UIKit/Text'
import { getFullDisplayBalance } from 'utils/formatBalance'

export type DepositRolloverOverviewProps = {
  selectedToken: Token
  totalDepositRollover: BigNumber
  openDepositRollovermodal: () => void
}

const DepositRolloverOverview: React.FC<DepositRolloverOverviewProps> = ({
  openDepositRollovermodal,
  selectedToken,
  totalDepositRollover,
}) => {
  return (
    <>
      {totalDepositRollover.isGreaterThan(BIG_ZERO) && (
        <RowMiddle mt="6px">
          <Text fontSize="10px" color="textSubtle">
            <Trans
              i18nKey="lockDepositRolloverAmount"
              components={{
                highlight_tag: <StyledActionText onClick={openDepositRollovermodal} />,
              }}
            />{' '}
            <Text fontSize="10px" lineHeight="normal" color="textSubtle" as="span">
              {getFullDisplayBalance(totalDepositRollover, 0, 5)} {selectedToken.name}
            </Text>
          </Text>
        </RowMiddle>
      )}
    </>
  )
}

const StyledActionText = styled(Text)`
  display: inline-block;
  font-size: 10px;
  color: ${({ theme: { colors } }) => colors.strokeAlt3};
  line-height: normal;
  text-decoration: underline;
  font-weight: 500;
  cursor: pointer;
`

export default DepositRolloverOverview
