import { DepositRolloverInfo } from 'config/types/payment'
import { RowBetween } from 'layout/Components/Row'
import styled from 'styled-components'
import { colors } from 'theme/colors'
import Box from 'UIKit/Box/Box'
import { BoxProps } from 'UIKit/Box/types'
import Progress from 'UIKit/Progress'
import { ProgressBar } from 'UIKit/Progress/StyledProgress'
import Text from 'UIKit/Text'
import TransText from 'UIKit/Text/TransText'
import { formatDisplayDate } from 'utils/dateHelper'
import { getFullDisplayBalance } from 'utils/formatBalance'
import { getTokenName } from 'utils/token'

type CardDetailsProps = BoxProps & { depositRolloverDetails: DepositRolloverInfo }
const MAX_PROGRESS = 100

const CardDetails: React.FC<CardDetailsProps> = ({ depositRolloverDetails, ...props }) => {
  const {
    createTime,
    bonusInfo: { bonusName },
    currentUnlockAmount,
    lockAmount,
    token,
  } = depositRolloverDetails
  const wagerAmountDisplay = getFullDisplayBalance(currentUnlockAmount, 0, 8)
  const lockDepositAmoutDisplay = getFullDisplayBalance(lockAmount, 0, 8)
  const tokenName = getTokenName(token)

  const percentOfProgress = currentUnlockAmount.isGreaterThan(lockAmount)
    ? MAX_PROGRESS
    : Math.floor(currentUnlockAmount.div(lockAmount).multipliedBy(MAX_PROGRESS).toNumber())

  return (
    <StyledCardDetails {...props}>
      <RowBetween>
        <TransText color="textSubtle" fontSize="10px" fontWeight={500} lineHeight="normal" letterSpacing="-0.2px">
          Active Bonus
        </TransText>
        <Text color="#7A7A7A" fontSize="10px" fontWeight={500} lineHeight="normal" letterSpacing="-0.2px">
          {formatDisplayDate(new Date(createTime))}
        </Text>
      </RowBetween>

      <Text fontSize="14px" fontWeight={800} lineHeight="normal" letterSpacing="-0.28px" mt="4px">
        {bonusName}
      </Text>

      <RowBetween my="8px">
        <TransText fontSize="12px" fontWeight={500} lineHeight="normal" letterSpacing="-0.24px" color="textSubtle">
          Your lock amount
        </TransText>
        <StyledValueDisplay>
          {lockDepositAmoutDisplay}
          <StyledTokenName as="span">{tokenName}</StyledTokenName>
        </StyledValueDisplay>
      </RowBetween>

      <Box>
        <Box mt="8px" color="#fff">
          <StyledProgress
            scale={'md'}
            primaryStep={percentOfProgress}
            showProgress={false}
            barStyles={{
              background: `${colors.success} !important`,
            }}
            isFull={percentOfProgress === MAX_PROGRESS}
          />
        </Box>

        <RowBetween mt="4px">
          <StyledValueDisplay>
            {wagerAmountDisplay} / {lockDepositAmoutDisplay}
            <StyledTokenName as="span">{tokenName}</StyledTokenName>
          </StyledValueDisplay>
          <Text fontSize="12px" fontWeight={700} letterSpacing="-0.24px" lineHeight="normal" color="textTertiary">
            {percentOfProgress}%
          </Text>
        </RowBetween>
      </Box>
    </StyledCardDetails>
  )
}

const StyledCardDetails = styled(Box)`
  width: 100%;
  padding: 12px;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors.textSubtle};
  backdrop-filter: blur(4.449999809265137px);
  background-image: url('/images/payment/deposit-rollover-card-bg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: 1px solid rgba(173, 173, 173, 0.2);
`
const StyledProgress = styled(Progress)<{ isFull: boolean }>`
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%), #6c727e !important;

  ${ProgressBar} {
    position: unset;
    border-top-right-radius: ${({ isFull }) => (isFull ? '32px' : '0px')};
    border-bottom-right-radius: ${({ isFull }) => (isFull ? '32px' : '0px')};
  }
  height: 6px !important;
  background: rgba(35, 44, 61, 0.8) !important;
  padding: 1px;
  margin-bottom: 4px;
`

const StyledValueDisplay = styled(Text)`
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.24px;
`
const StyledTokenName = styled(Text)`
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.24px;
  color: #6c727e;
  margin-left: 3px;
`

export default CardDetails
