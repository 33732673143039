import Box from 'UIKit/Box/Box'
import OpenEffect from 'UIKit/OpenEffect'
import { Position, PositionProps } from 'UIKit/Popper/typpes'
import useDebounce from 'hooks/useDebounce'
import useDebounceCallback from 'hooks/useDebounceCallback'
import React, { useRef, useState } from 'react'
import { animated } from 'react-spring'
import styled, { css } from 'styled-components'
import { DropdownProps } from './types'

const getLeft = ({ position }: PositionProps) => {
  if (position === 'top-right') {
    return 'unset'
  }

  if (position === 'top-left') {
    return '0%'
  }

  if (position === 'bottom-left') {
    return '0%'
  }

  if (position === 'bottom-right') {
    return 'unset'
  }

  return '50%'
}

const getRight = ({ position }: PositionProps) => {
  if (position === 'bottom-right' || position === 'top-right') {
    return '0%'
  }

  return 'unset'
}

const getTransformWhenActive = ({ position }: any) => {
  if (
    position === 'bottom-left' ||
    position === 'bottom-right' ||
    position === 'top-left' ||
    position === 'top-right'
  ) {
    return 'translate(0%, 0%)'
  }
  return 'translate(-50%, 0%)'
}

const getBottom = ({ position }: PositionProps) => {
  if (position === 'top' || position === 'top-right' || position === 'top-left') {
    return '100%'
  }
  return 'auto'
}

const Dropdown: React.FC<DropdownProps> = ({ children, dropdownPosition, dropdownContent, ...props }) => {
  const [open, setOpen] = useState(false)
  const openDebounced = useDebounce(open, 500)
  const debounce = useDebounceCallback()
  const debounceRef = useRef(null)

  return (
    <Box
      width="100%"
      height="100%"
      position="relative"
      onMouseOver={() => {
        clearTimeout(debounceRef.current)
        setOpen(true)
      }}
      onMouseOut={() => {
        debounceRef.current = debounce(() => {
          setOpen(false)
        }, 150)
      }}
    >
      <StyledAnchorWrapper {...props}>{children}</StyledAnchorWrapper>

      <StyledMenuDropdownWrapper $open={open} position={dropdownPosition}>
        {(open || openDebounced) && (
          <OpenEffect openType="fade">
            {React.isValidElement(dropdownContent) ? (
              React.cloneElement(dropdownContent, {
                onDismiss: () => setOpen(false),
              } as any)
            ) : (
              <>{dropdownContent}</>
            )}
          </OpenEffect>
        )}
      </StyledMenuDropdownWrapper>
    </Box>
  )
}

const StyledMenuDropdownWrapper = styled(animated.div)<{ position: Position; $open: boolean }>`
  flex-direction: column;
  position: absolute;
  overflow-y: auto;
  z-index: ${({ theme }) => theme.zIndices.dropdown};

  bottom: ${getBottom};
  left: ${getLeft};
  right: ${getRight};

  display: flex;
  transform: ${getTransformWhenActive} !important;
  overflow: visible;
  min-width: 100%;
  width: max-content;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  margin-top: 20px;
  transition: 0.3s all;

  ${({ $open }) =>
    $open &&
    css`
      margin-top: 0px;
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
    `}
`
const StyledAnchorWrapper = styled(Box)`
  width: 100%;
  height: 100%;

  cursor: pointer;
`

export default Dropdown
