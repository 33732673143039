// Level Up Bonus
export const regexLevelUpManyLevelAndDate = /Increase\s+([\d.]+)\s+VIP\s+levels\s+for\s+([\d.]+)\s+days/
export const regexLevelUpManyDay = /Increase\s+([\d.]+)\s+VIP\s+level\s+for\s+([\d.]+)\s+days/
export const regexLevelUpManyLevel = /Increase\s+([\d.]+)\s+VIP\s+levels\s+for\s+([\d.]+)\s+day/
export const regexLevelUp = /Increase\s+([\d.]+)\s+VIP\s+level\s+for\s+([\d.]+)\s+day/

// Free Lucky Spin Bonus
export const regexMultiLuckySpin = /Receive\s+([\d.]+)\s+lucky\s+spins/
export const regexLuckySpin = /Receive\s+([\d.]+)\s+lucky\s+spin/

// HUSD Bonus
export const regexHUSD = /Receive\s+([\d.]+)\s+locked\s+HUSD/

// Wager Bonus
export const regexWagerBonus = /Receive\s+([\d.]+)\s+(\w+)/

// Deposit Bonus
export const regexDeposit = /([\d.]+)%\s+on\s+the\s+deposit/

// Freespin Bonus
export const regexMultiFreeSpin = /([\d.]+)\s+free\s+spins/
export const regexFreeSpin = /([\d.]+)\s+free\s+spin/

// Deposit Freespin Bonus
export const regexDepositMultiFreeSpin = /([\d.]+)\s+free\s+spins/
export const regexDepositFreeSpin = /([\d.]+)\s+free\s+spin/

// Cash Bonus
export const regexCashBonus = /Receive\s+([\d.]+)\s+(\w+)/

// Deposit Cash Bonus
export const regexDepositCashBonus = /Receive\s+([\d.]+)\s+(\w+)\s+on\s+Deposit/

// Booster Wager Bonus
export const regexBoosterWager = /([\d.]+)%\s+wager\s+booster/
