import { Trans } from 'react-i18next'
import Link from 'UIKit/Link'
import { LinkProps } from 'UIKit/Link/types'

const OpenLinkBtn: React.FC<{ href: string; children: React.ReactNode } & LinkProps> = ({
  children,
  href,
  ...props
}) => {
  return (
    <Link
      color="primary"
      fontSize={['12px', '', '14px']}
      fontWeight={400}
      lineHeight="20px"
      width="fit-content"
      target="_blank"
      href={href}
      external
      {...props}
    >
      <Trans>{children}</Trans>
    </Link>
  )
}

export default OpenLinkBtn
