import Column from 'layout/Components/Column'
import { BoxProps } from 'UIKit/Box/types'
import FairnessSecurity from 'views/Commitments/section/FairnessSecurity'
import PaymentRules from 'views/Commitments/section/PaymentRules'

const PaymentCommitments: React.FC<BoxProps> = ({ ...props }) => {
  return (
    <Column {...props}>
      <PaymentRules />
      <FairnessSecurity />
    </Column>
  )
}

export default PaymentCommitments
