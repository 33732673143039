import { ModalProps } from 'UIKit/Modal'
import { TableRowValueHistory } from 'UIKit/Table/TableHistory'
import Text from 'UIKit/Text'
import { NETWORK_UNKNOWN } from 'config/constants/network'
import tokens from 'config/constants/tokens'
import { UsableFiatCurrencies } from 'config/constants/tokens/fiat'
import { UserBonus } from 'config/types/bonus/userBonus'
import { PendingTransaction, Transaction } from 'config/types/transaction'
import Column from 'layout/Components/Column'
import { RowBetween, RowCenter, RowMiddle } from 'layout/Components/Row'
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import base from 'theme/base'
import { colors } from 'theme/colors'
import { getNetworkIncludeFiatInfo } from 'utils/network'
import { isFiatCurrency } from 'utils/token'
import { buildBonusName } from 'views/BonusCenter/hooks'
import { useResumeProgressingDepositFiat } from 'views/Payment/components/Deposit/DepositByFiat/hooks'
import {
  FieldTitle,
  SectionTransactionAmount,
  TransactionHash,
  TransactionStatus,
  TransactionWrapperModal,
} from '../styled'
import DepositFiatProgressInfo from './DepositFiatProgressInfo'
import ResumeProgressingDepositFiatCountdown from './ResumeProgressingDepositFiatCountdown'

const DepositTransactionDetailsModal: React.FC<
  ModalProps<{
    transaction: Transaction
    bonusInfo: UserBonus
  }>
> = ({ data, onDismiss }) => {
  const { t } = useTranslation()

  const { transaction, bonusInfo } = data
  const token = tokens[transaction.network]?.[transaction.currency]
  const { network = NETWORK_UNKNOWN } = getNetworkIncludeFiatInfo(transaction.network)
  const { countdownTime } = useResumeProgressingDepositFiat(transaction.createTime)
  const isAbleToResume = countdownTime >= new Date() && PendingTransaction.includes(transaction.status)

  const [isEndResume, setIsEndResume] = useState(!isAbleToResume)
  const isFiatTransaction = isFiatCurrency(transaction.currency)

  return (
    <TransactionWrapperModal title={t('Deposit Details')} onDismiss={onDismiss}>
      <SectionTransactionAmount title="Deposit" amount={transaction.value} token={token} date={transaction.createTime}>
        {!isEndResume && UsableFiatCurrencies.includes(transaction.currency) && isAbleToResume && (
          <RowCenter
            mx="auto"
            mb="16px"
            position="relative"
            top={'-8px'}
            background={colors.backgroundAlt5}
            padding="8px 12px"
            borderRadius={base.radii.small}
          >
            <Text fontSize="12px" style={{ whiteSpace: 'nowrap' }} fontWeight={600} color="textSubtle" mr="8px">
              <Trans>Expired in</Trans>
            </Text>
            <ResumeProgressingDepositFiatCountdown
              endDate={countdownTime.getTime()}
              onResumeEnd={() => setIsEndResume(true)}
            />
          </RowCenter>
        )}

        <RowBetween>
          <FieldTitle>
            <Trans>Status</Trans>
          </FieldTitle>
          <Column alignItems="flex-end">
            <TransactionStatus status={transaction.status} />
          </Column>
        </RowBetween>
        {bonusInfo && (
          <RowBetween mt="12px">
            <FieldTitle>
              <Trans>Bonus Activated</Trans>
            </FieldTitle>
            <TableRowValueHistory>{buildBonusName(t, bonusInfo.info?.name)[0]}</TableRowValueHistory>
          </RowBetween>
        )}

        {!isFiatCurrency(transaction.currency) && (
          <RowBetween mt="12px">
            <FieldTitle>
              <Trans>Network</Trans>
            </FieldTitle>
            <TableRowValueHistory>{network?.networkInfo.displayName}</TableRowValueHistory>
          </RowBetween>
        )}
        {isFiatCurrency(transaction.currency) && (
          <RowBetween mt="12px">
            <FieldTitle>
              <Trans>Payment Method</Trans>
            </FieldTitle>
            <TableRowValueHistory>{transaction?.fiat?.bankName || ''}</TableRowValueHistory>
          </RowBetween>
        )}

        <RowBetween mt="12px">
          <FieldTitle>
            <Trans>Transaction ID</Trans>
          </FieldTitle>
          <RowMiddle>
            <TransactionHash
              txnHash={transaction.txnHash}
              chainId={transaction.network}
              hideHyperLink={isFiatTransaction || false}
            />
          </RowMiddle>
        </RowBetween>
      </SectionTransactionAmount>

      {isFiatCurrency(transaction.currency) && (
        <DepositFiatProgressInfo transaction={data.transaction} isDisableResumeTransaction={isEndResume} />
      )}
    </TransactionWrapperModal>
  )
}

export default DepositTransactionDetailsModal
