import BigNumber from 'bignumber.js'
import { ChainIdEnum } from 'config/constants/network'
import { Token } from 'config/types'
import { TipTransactionFilterTypeEnums } from 'config/types/transaction'
import useModal from 'hooks/useModal'
import useQueryParam from 'hooks/useQueryParam'
import { useRequest } from 'hooks/useRequest'
import { useRouter } from 'hooks/useRouter'
import { useEffect, useMemo, useState } from 'react'
import PaymentService from 'services/PaymentService'
import { DepositRolloverInfo } from 'config/types/payment'
import { BIG_ZERO } from 'config/constants/number'
import TipDetailModal from './components/TipDetailModal'

export const usePresentTipDetailModal = () => {
  const router = useRouter()
  const { execute } = useRequest()
  const [onPresentTipDetailModal] = useModal(TipDetailModal)
  const { removeParam } = useQueryParam()

  const handlePresentTipDetailModal = async () => {
    const { id } = router.query
    if (id) {
      removeParam('id')
      const response = await execute(PaymentService.getTipDetail(id as string))
      if (response?.data) {
        onPresentTipDetailModal({
          details: response.data,
          target: TipTransactionFilterTypeEnums.Receive,
        })
      }
    }
  }

  return handlePresentTipDetailModal
}

export const useLockAmountTipInfo = (token: Token) => {
  const [lockAmount, setLockAmount] = useState<{
    lockBonusAmount: BigNumber
    bonusId?: number
  }>({ lockBonusAmount: BIG_ZERO })
  const [depositRolloverList, setDepositRolloverList] = useState<DepositRolloverInfo[]>([])

  const { execute } = useRequest()

  useEffect(() => {
    const fetch = async () => {
      const response = await execute(
        PaymentService.getTipInfo({
          currency: token.code,
          network: ChainIdEnum[token.network],
        }),
      )

      if (response?.data) {
        setLockAmount({
          lockBonusAmount: new BigNumber(response.data.lockBonusAmount),
          bonusId: response.data.voucherId,
        })
        setDepositRolloverList(response.data.depositRolloverList)
        return
      }

      if (depositRolloverList.length > 0) setDepositRolloverList([])
      if (lockAmount.lockBonusAmount.isGreaterThan(BIG_ZERO)) setLockAmount({ lockBonusAmount: BIG_ZERO })
    }

    fetch()
  }, [token])

  return useMemo(() => ({ lockAmount, depositRolloverList }), [lockAmount, depositRolloverList])
}
