import { Trans } from 'react-i18next'
import Text, { TextProps } from 'UIKit/Text'

const HighlightText: React.FC<{ translateKey: string; children?: React.ReactNode } & TextProps> = ({
  translateKey,
  children,
  ...props
}) => {
  return (
    <Text
      fontSize={['12px', '', '14px']}
      fontWeight={400}
      color="textSubtle"
      lineHeight={['15px', '', '18px']}
      {...props}
    >
      {children}
      <Trans
        i18nKey={translateKey}
        components={{
          highlight_text: (
            <Text
              color="text"
              fontSize={['12px', '', '14px']}
              lineHeight={['15px', '', '18px']}
              fontWeight={600}
              as="span"
            />
          ),
        }}
      />
    </Text>
  )
}

export default HighlightText
