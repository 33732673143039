import { StaticJsonRpcProvider } from '@ethersproject/providers'
import { web3 } from '@project-serum/anchor'
import { ChainIdEnum, NETWORK_MAP } from 'config/constants/network'
import { isOutSideEvmChain, isSolChain } from './network'

export class SimpleRpcProvider {
  private static _simpleRpcProviderMap = {}

  public static get(chainId: ChainIdEnum) {
    if (!this._simpleRpcProviderMap[chainId]) {
      if (isSolChain(chainId)) {
        const rpcCollections = NETWORK_MAP[chainId]?.rpcCollections || []
        const selectedRpcUrl = getRandomRpcFromList(rpcCollections)
        this._simpleRpcProviderMap[chainId] = new web3.Connection(selectedRpcUrl, {
          confirmTransactionInitialTimeout: 60 * 10 * 1000,
        })
      } else if (isOutSideEvmChain(chainId)) {
        this._simpleRpcProviderMap[chainId] = null
      } else {
        const rpcCollections = NETWORK_MAP[chainId]?.rpcCollections || []
        const selectedRpcUrl = getRandomRpcFromList(rpcCollections)
        this._simpleRpcProviderMap[chainId] = new StaticJsonRpcProvider(selectedRpcUrl)
      }
    }

    return this._simpleRpcProviderMap[chainId]
  }
}

export const getRandomRpcFromList = (rpcCollections: string[]) => {
  if (rpcCollections.length) {
    const randomRpc = rpcCollections[Math.floor(Math.random() * 3)]

    if (randomRpc) return randomRpc
    return rpcCollections[0]
  }

  return ''
}

export const getSimplerRpcProvider = (chainId: ChainIdEnum) => {
  return SimpleRpcProvider.get(chainId)
}
