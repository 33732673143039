import { useMemo, useState } from 'react'

const RECENT_WALLET = 'recent_wallet'

const useRecentWallet = () => {
  const [recentWallet, setRecentWallet] = useState(localStorage.getItem(RECENT_WALLET))

  const updateRecentWallet = (walletName: string) => {
    localStorage.setItem(RECENT_WALLET, walletName)
    setRecentWallet(walletName)
  }

  return useMemo(
    () => ({
      recentWallet,
      updateRecentWallet,
    }),
    [recentWallet, updateRecentWallet],
  )
}

export default useRecentWallet
