import { useState } from 'react'
import styled from 'styled-components'
import { colors } from 'theme/colors'
import Box from 'UIKit/Box/Box'
import Tab from 'UIKit/Tab'
import Tabs from 'UIKit/Tabs'
import Text from 'UIKit/Text'
import TransText from 'UIKit/Text/TransText'
import FairnessSecurity from 'views/Commitments/section/FairnessSecurity'
import PaymentRules from 'views/Commitments/section/PaymentRules'

enum InformationTags {
  PaymentRules,
  FairnessSecurity,
}

const CommitmentsTabInfo: React.FC = () => {
  const [tab, setTab] = useState(InformationTags.PaymentRules)

  return (
    <StyledCommitmentsTabInfo width="100%" borderTop={'2px solid  rgba(255, 255, 255, 0.04)'} position="relative">
      <StyledTabs
        mt={['16px', '', '20px']}
        mb={['12px', '', '20px']}
        value={tab}
        onTagChanged={setTab}
        backgroundBlurColor={colors.background}
        indicator={<StyledIndicator />}
        $active={tab}
        width="100%"
      >
        <StyledTab value={InformationTags.PaymentRules} $active={tab === InformationTags.PaymentRules}>
          <TransText fontWeight={600} fontSize="14px" lineHeight="24px" width="auto">
            Deposit & Withdrawal
          </TransText>
        </StyledTab>

        <StyledTabStroke disabled value={null}>
          <StyledStroke />
        </StyledTabStroke>

        <StyledTab value={InformationTags.FairnessSecurity} $active={tab === InformationTags.FairnessSecurity}>
          <TransText fontWeight={600} fontSize="14px" lineHeight="24px" width="auto">
            Fairness & Security
          </TransText>
        </StyledTab>
      </StyledTabs>

      {tab === InformationTags.PaymentRules ? <PaymentRules /> : <FairnessSecurity />}
    </StyledCommitmentsTabInfo>
  )
}

const StyledCommitmentsTabInfo = styled(Box)`
  .information-title {
    font-weight: 600;
  }
`
const StyledTab = styled(Tab)<{ $active: boolean }>`
  width: calc(50% - 7px);
  height: 40px;

  line-height: 16px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  svg {
    min-width: 20px;
    transition: ${({ theme }) => theme.transitions.fast};
    fill: ${({ theme, $active }) => ($active ? `${theme.colors.text} !important` : theme.colors.textSubtle)};
  }

  ${Text} {
    transition: ${({ theme }) => theme.transitions.fast};
    color: ${({ theme, $active }) => ($active ? `${theme.colors.text} !important` : theme.colors.textSubtle)};
    white-space: pre !important;
    font-weight: 600;
    font-size: 12px;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    padding: 8px;
    ${Text} {
      font-size: 14px;
    }
  }
`

const StyledTabs = styled(Tabs)<{ $active: number }>`
  padding: 4px;
  background: rgba(255, 255, 255, 0.04);
  border-radius: ${({ theme }) => theme.radii.tiny};
  box-shadow: 0px 2px 4px 4px rgba(0, 0, 0, 0.05);
  width: 100%;
  & > div > div {
    justify-content: space-between;
  }
`

const StyledTabStroke = styled(Tab)`
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  width: 16px;
  cursor: auto;
`

const StyledStroke = styled(Box)`
  width: 1px;
  height: 16px;
  background: #232c3d;
`

const StyledIndicator = styled.div`
  border-radius: ${({ theme }) => theme.radii.tiny};
  background: rgba(255, 255, 255, 0.08);
  height: 100%;
  width: 100%;
`

export default CommitmentsTabInfo
