import { Token } from 'config/types'
import { Game, GameSession } from 'config/types/game'
import { useRequest } from 'hooks/useRequest'
import { useCallback, useMemo, useState } from 'react'
import GameService from 'services/GameService'

export const useFreeSpinCreateGameSession = (selectedToken: Token | null) => {
  const [isLoadingSource, setIsLoadingSource] = useState(false)
  const { execute } = useRequest()
  const [gameUrl, setGameUrl] = useState('')

  const fetchFn = useCallback(
    async (game: Game): Promise<GameSession> => {
      if (!selectedToken) return
      setIsLoadingSource(true)

      const result = await execute(GameService.createGameSession(game.code, selectedToken.code, selectedToken.network))
      const { gameUrl } = result?.data || {}

      setIsLoadingSource(false)
      if (gameUrl) {
        setGameUrl(gameUrl)
      }

      return result?.data
    },
    [selectedToken],
  )

  return useMemo(
    () => ({
      isLoadingSource,
      gameUrl,
      fetchFn,
    }),
    [selectedToken, isLoadingSource, gameUrl],
  )
}
