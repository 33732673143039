import Box from 'UIKit/Box/Box'
import Flex from 'UIKit/Box/Flex'
import Button from 'UIKit/Button/Button'
import FormControl from 'UIKit/FormControl'
import FormInput from 'UIKit/FormControl/FormInput'
import { StyledInput } from 'UIKit/Input/styled'
import CircleLoader from 'UIKit/Loader/CircleLoader'
import Modal, { ModalBody, ModalProps } from 'UIKit/Modal'

import Text from 'UIKit/Text/Text'
import { ValidationError } from 'config/types/validator'
import useForm from 'hooks/useForm'
import useToggle from 'hooks/useToggle'
import { RowCenter } from 'layout/Components/Row'
import { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useAuth } from 'state/auth/hooks'
import styled from 'styled-components'
import { Icons } from 'svgs'
import theme from 'theme'
import { HunnyToast } from 'utils/toastify'
import { useRedeemBonus } from 'views/BonusCenter/hooks'
import DisplaySocialMedia from '../DisplaySocialMedia/DisplaySocialMedia'

interface ClaimBonusModalProps {
  onClaimed: () => void
}

const ClaimBonusModal: React.FC<ModalProps<ClaimBonusModalProps>> = ({ onDismiss, data: { onClaimed } }) => {
  const { t } = useTranslation()
  const redeemBonus = useRedeemBonus()
  const { hasSession } = useAuth()
  const [claiming, toggleClaiming] = useToggle(false)
  const { states, controls, isValid } = useForm({
    code: {
      validators: [],
      value: '',
    },
  })

  useEffect(() => {
    controls.code.setDisabled(!hasSession)
  }, [hasSession])

  const handleRedeemBonus = async () => {
    toggleClaiming()
    const response = await redeemBonus(states.code.value)

    if (response) {
      controls.code.onErrorChanged([response as any])
    } else {
      controls.code.clear()
      HunnyToast.success(t('Congrats!'), t('The bonus has been added to your list and is now available for use.'))
      onClaimed()
      onDismiss()
    }

    toggleClaiming()
  }

  const formatClaimBonusErrorMessages = (error: string) => {
    if (error) {
      return (
        <Flex alignItems="flex-start" mt="8px">
          <Box minWidth="16px">
            <Icons.CloseCircleIcon width="16px" height="16px" fill={theme.colors.error} />
          </Box>
          <Text color={theme.colors.error} fontSize="12px" ml="6px" lineHeight="16px">
            {error}
          </Text>
        </Flex>
      )
    }

    return <></>
  }

  return (
    <Modal onDismiss={onDismiss} maxWidth="568px !important" minWidth={['', '', '440px !important']}>
      <ModalBody p="20px">
        <Box mt="20px">
          <Text fontSize="20px" fontWeight="700" textAlign="center">
            <Trans>Enter your bonus code</Trans>
          </Text>
          <Text fontSize="14px" lineHeight="16px" fontWeight="500" color="textSubtle" textAlign="center" mt="8px">
            <Trans>Unlock a world of rewards with HunnyPlay's exclusive bonus codes!</Trans>
          </Text>
        </Box>
        <Flex
          mt="24px"
          flexDirection="column"
          as="form"
          onSubmit={(e) => {
            e.preventDefault()
            if ((!isValid && states.code.errors[0] !== ValidationError.ValueSubmited) || claiming || !states.code.value)
              return

            handleRedeemBonus()
            return
          }}
        >
          <RowCenter flexDirection="column" width="100%">
            <StyledFormControl
              state={states.code}
              formatErrorMessage={(errors: ValidationError[]) => formatClaimBonusErrorMessages(errors?.[0])}
            >
              <StyledFormInput
                tabIndex={1}
                control={controls.code}
                placeholder={t('Enter your bonus code')}
                name="code"
              />
            </StyledFormControl>
          </RowCenter>

          <StyledButton
            mt="36px"
            variant="highlight"
            minWidth={['unset', 'unset', 100, 200]}
            height={48}
            disabled={
              (!isValid && states.code.errors[0] !== ValidationError.ValueSubmited) ||
              claiming ||
              !states.code.value.trim()
            }
            onClick={handleRedeemBonus}
          >
            {claiming && (
              <Box mr="12px">
                <CircleLoader />
              </Box>
            )}
            <Text
              fontSize={['14px', '14px', '16px']}
              color={claiming ? 'textSubtle' : 'text'}
              id={'enterBonusCodeModal-claimBonusBtn'}
              bold
            >
              <Trans>Claim</Trans>
            </Text>
          </StyledButton>

          <Box mt="20px">
            <Flex justifyContent="center" alignItems="center">
              <Text fontSize="14px" mr="10px" textAlign="center" color="textSubtle">
                <Trans>Follow us on social media to find bonus codes</Trans>
              </Text>
            </Flex>
            <Box width="100%" mt="10px">
              <DisplaySocialMedia />
            </Box>
          </Box>
        </Flex>
      </ModalBody>
    </Modal>
  )
}

const StyledFormControl = styled(FormControl)`
  ${StyledInput} {
    max-height: 48px;
  }
`

const StyledFormInput = styled(FormInput)`
  text-transform: uppercase;
`

const StyledButton = styled(Button)`
  &:disabled {
    background: #1d2533;

    ${Text} {
      color: ${({ theme }) => theme.colors.textDisable};
    }
  }
`

export default ClaimBonusModal
