import { RouteConfig } from 'config/constants/route'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Icons } from 'svgs'
import HighlightText from '../components/HighlightText'
import Property from '../components/Property'
import InformationDetails from '../components/InformationDetails'
import OpenLinkBtn from '../components/OpenLinkBtn'

const FairnessSecurity = () => {
  const { t } = useTranslation()

  return (
    <>
      <StyledInformationDetails
        icon={<Icons.JudgeIcon width="24px" />}
        title={t('{{amount}} Fair and Trusted Games from Top Providers', { amount: '5,000+' })}
        mb="32px"
      >
        <HighlightText translateKey="gamesIntroduction" mt="16px" />
        <Property src="/images/commitments/check-square.png" mt="12px">
          Certified Fair Games
        </Property>
        <Property src="/images/commitments/small-dart.png">Transparent RTP & volatility stats</Property>
        <Property src="/images/commitments/joystick.png">Supports Fun mode and Real mode</Property>
        <Property src="/images/commitments/medal.png">Trust the quality. Play with confidence.</Property>

        <OpenLinkBtn href={RouteConfig.Games} mt="16px">
          Explore Games
        </OpenLinkBtn>
      </StyledInformationDetails>

      <InformationDetails
        icon={<Icons.LockCircleIcon width="24px" />}
        title="Your privacy and security are our top priorities."
        mb="32px"
      >
        <HighlightText translateKey="yourSecurity" mt="16px" />
        <Property src="/images/commitments/check-square.png" mt="8px">
          Fully On-Chain – Every transaction is secure, visible, and verifiable.
        </Property>
        <Property src="/images/commitments/check-square.png">
          Advanced Encryption – Your data is encrypted and protected
        </Property>
        <Property src="/images/commitments/check-square.png">
          Automated Transactions - Reducing risks and delays.
        </Property>
      </InformationDetails>
    </>
  )
}
const StyledInformationDetails = styled(InformationDetails)`
  a {
    display: block;
    width: fit-content;
  }
`

export default FairnessSecurity
