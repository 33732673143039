import React from 'react'
import styled from 'styled-components'
import Box from 'UIKit/Box/Box'
import { CloseButtonModal } from 'UIKit/Modal/styled'
import useIframeUpdater from 'views/GameDetails/GameDesktopIframe/hooks/useIframeUpdater'

const FreeSpinMobileGameIframe: React.FC<{ iframeUrl: string; onBack: () => void }> = ({ iframeUrl, onBack }) => {
  const { iframeContainerRef } = useIframeUpdater(iframeUrl)

  return (
    <Container>
      <CloseButtonModal onDismiss={onBack} />
      <StyledIframe
        ref={iframeContainerRef}
        style={{
          display: iframeUrl ? 'block' : 'none',
        }}
      />
    </Container>
  )
}

const Container = styled(Box)`
  background-color: ${({ theme }) => theme.colors.background};
  bottom: 0;
  height: 100vh;
  left: 0;
  position: absolute !important;
  right: 0;
  width: 100vw;
  z-index: 1000;

  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledIframe = styled(Box)`
  iframe {
    border-radius: 14px;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`

export default FreeSpinMobileGameIframe
