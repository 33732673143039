import { ChainIdEnum } from 'config/constants/network'
import tokens, { USD_CURRENCY } from 'config/constants/tokens'
import { DepositedTokenInfo, GameTag, ProviderInfo } from 'config/types'
import { ProfileTierInfo } from 'config/types/profile'
import { PinnedPromotion } from 'config/types/promotion'
import { parseTierInfos } from 'services/mapper/utils'
import { BaseResponse, MetaDataResponse, Paging, TokenUsdPriceResponse } from 'services/types'
import { formatApiNetworkField } from 'utils'
import { isTrxChain } from 'utils/network'
import { SentryHelper } from 'utils/sentryHelper'
import { parseScreenTags } from './Game'
import { parseDataWelcomePack } from './Voucher'

const enum CommitmentSettingResponseEnum {
  Show = 1,
  Hide = -1,
}

export const MetaDataMapper = (rawResponse: string): BaseResponse<MetaDataResponse> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  const data = response?.data

  if (!response || response?.code !== 'success') {
    return {
      code: response?.code || 'error',
      data: null,
    }
  }

  const tokenDepositAddressMap = data?.wallets?.reduce((result, cur) => {
    if (!cur?.currencies) return result

    for (const currency of cur.currencies) {
      result[`${currency}${formatApiNetworkField(cur.network)}`] = cur.address
    }
    return result
  }, {})

  const currencySwapInfo = data?.['swap_info']?.['to_tokens'] || []
  const settingCurrenciesMappings = data?.['setting_currencies'] || []

  const settingCurrencies: {
    [key: string]: {
      token: string
      network: ChainIdEnum
      depositable: number
      playable: number
      withdrawalable: number
    }
  } = settingCurrenciesMappings.reduce((state, item) => {
    const key = `${item.currency}${formatApiNetworkField(item.network)}`

    if (!ChainIdEnum[formatApiNetworkField(item.network)]) return state

    state[key] = {
      token: item.currency,
      network: ChainIdEnum[formatApiNetworkField(item.network)],

      depositable: item.depositable,
      playable: item.playable,
      withdrawalable: item.withdrawalable,
    }
    return state
  }, {})

  const tokenInfoes: DepositedTokenInfo[] = settingCurrenciesMappings

    .map((item: any): DepositedTokenInfo => {
      const key = `${item.currency}${formatApiNetworkField(item.network)}`

      const setting = settingCurrencies?.[key]
      const network = ChainIdEnum[formatApiNetworkField(item.network)]
      const tokenEstablished = tokens?.[network]?.[item.currency]

      if (!setting || !tokenEstablished) {
        SentryHelper.captureFeatureClientError({
          feature: 'Currency',
          error: new Error(`Currency: ${item.currency} is not exist in config`),
        })
        return
      }

      if (!network) {
        SentryHelper.captureFeatureClientError({
          feature: 'Network',
          error: new Error(`Network: ${item.network} is not exist in config`),
        })

        return
      }

      /*
        Disable: -1
        Enable: 1
      */
      const isDisabledDeposit = setting.depositable === -1
      const isDisabledWithdraw = setting.withdrawalable === -1
      const isDisabledPlayable = setting.playable === -1

      return {
        network,
        token: item.currency,
        // TODO: disable deposit trx via smart contract
        contractAddress: !isTrxChain(network) && tokenDepositAddressMap[key],

        disabledDeposit: isDisabledDeposit,
        disabledWithdrawal: isDisabledWithdraw,
        disabledPlay: isDisabledPlayable,
        disabledSwap: !currencySwapInfo.find(
          (swapableCurrency) =>
            swapableCurrency.network === item.network && item.currency === swapableCurrency.currency,
        ),
      }
    })
    .filter((item) => item)

  const tags: GameTag[] = data?.tags
    ?.sort((pre: any, next: any) => pre.priority - next.priority)
    .map(
      (tag: any): GameTag => ({
        content: tag.name,
        img: tag.icon_url,
        value: tag.code,
      }),
    )

  const networkIds: string[] = data?.['networks']
    ?.filter((network) => network.code !== 'HPN')
    .map((network: any) => formatApiNetworkField(network.code))

  const profileTierInfos: ProfileTierInfo[] = data?.tiers
    ?.map((tier) => parseTierInfos(tier))
    .sort((pre: ProfileTierInfo, next: ProfileTierInfo) => pre.level - next.level)

  const defaultScreenTags = parseScreenTags(data?.menu_allgame_tags)
  const welcomePackage = parseDataWelcomePack(data?.welcome_package)

  const pinPromotions = data?.pin_promo_contents?.map(
    (promo: any): PinnedPromotion => ({
      slug: promo.slug,
      menuImageUrl: promo.thumb_navi_url,
    }),
  )
  const providers = data?.providers?.map((provider) => parseProvider(provider))
  const minWithdrawTokenAmounts = data?.min_withdrawal
  const isDisplayCommitment = data?.display_setting?.commitment_display === CommitmentSettingResponseEnum.Show

  return {
    code: response.code,
    data: {
      serverTime: (data?.['server_time'] || 0) * 1000,
      timeChangeDisplayNameInDays: data?.['change_display_name_limit_day'],
      providers,
      defaultScreenTags,
      tags,
      networkIds,
      tokenInfoes,
      tiers: profileTierInfos,
      minWithdrawTokenAmounts,
      pinPromotions,
      welcomePackage,
      isDisplayCommitment,
    },
  }
}

export const parseProvider = (item) => ({
  name: item.name,
  code: item.code,
  icon: item.icon_url || item.logo_url,
  logo: item.logo_url,
  banner: item.banner_url,
  slug: item.slug,
  blacklistCountry: item['black-countries'] || [],
})

export const ProviderListMapper = (rawResponse: string): BaseResponse<Paging<ProviderInfo[]>> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    data: response.data && {
      items: response.data.items.map((item) => parseProvider(item)),
    },
  }
}

export const ProviderMapper = (rawResponse: string): BaseResponse<ProviderInfo> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }
  const provider = response.data?.provider

  return {
    code: response.code,
    data: provider && {
      name: provider.name,
      code: provider.code,
      icon: provider.icon_url || provider.logo_url,
      logo: provider.logo_url,
      desktopBanner: provider.banner_desktop_url,
      mobileBanner: provider.banner_mobile_url,
      slug: provider.slug,
      blacklistCountry: provider['black-countries'] || [],
    },
  }
}

export const TokenInUsdPriceMapper = (rawResponse: string): BaseResponse<TokenUsdPriceResponse> => {
  const response = JSON.parse(rawResponse)

  return {
    code: response.code,
    data:
      response.data &&
      response.data.items.reduce(
        (result, value) => {
          result[value.code] = value['price_usd']
          return result
        },
        { [USD_CURRENCY.code]: 1 },
      ),
  }
}
