import { RowBetween, RowMiddle } from 'layout/Components/Row'
import styled from 'styled-components'
import { Icons } from 'svgs'
import Box from 'UIKit/Box/Box'
import { BoxProps } from 'UIKit/Box/types'
import Button from 'UIKit/Button'
import TransText from 'UIKit/Text/TransText'

const PaymentCommitmentsBar: React.FC<BoxProps & { handleClick: () => void }> = ({ handleClick, ...props }) => {
  return (
    <StyledCommitments
      height={['28px', '', '', '24px']}
      p={['4px 8px !important', '', '', '2px 8px !important']}
      onClick={handleClick}
      {...props}
    >
      <RowBetween>
        <RowMiddle pr="16px" mr="16px" height="20px">
          <Box width="20px" height="20px">
            <Icons.ProtectIcon width="100%" height="100%" />
          </Box>
          <TransText ml="4px" fontSize="12px" fontWeight={500}>
            HunnyPlay's Commitments
          </TransText>
        </RowMiddle>

        <Icons.ChevronRightIcon width="6px" height="9px" fill="#fff" />
      </RowBetween>
    </StyledCommitments>
  )
}

const StyledCommitments = styled(Button)`
  background: #12b76a;
  width: 100%;
  border-radius: 4px;
  &:hover {
    transition: none !important;
    transform: none !important;
  }
`
export default PaymentCommitmentsBar
