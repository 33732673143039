import BigNumber from 'bignumber.js'
import { ChainIdEnum } from 'config/constants/network'
import { BIG_ZERO } from 'config/constants/number'
import tokens from 'config/constants/tokens'
import { QueryDepositStatusEnums } from 'config/constants/transactions'
import {
  DepositFiatPaymentMethod,
  DepositRolloverBase,
  FiatMethodCategory,
  LockAmountTipInfo,
  TipDetails,
  WithdrawFiatPaymentMethod,
  WithdrawInfo,
} from 'config/types/payment'
import { BetDetails } from 'config/types/profile'
import {
  FiatPaymentTransaction,
  QueryDepositInfo,
  RewardTransaction,
  SwapTransaction,
  TipTransaction,
  Transaction,
  TransactionTypeEnum,
} from 'config/types/transaction'
import { BalanceResponse, BaseResponse, Paging, PrepareSignMessageResponse, VerificationResponse } from 'services/types'
import { formatApiNetworkField } from 'utils'
import { isFiatCurrencyV2 } from 'utils/token'
import { parseBetDetailsItemMapper } from './Game'
import {
  parseBonusTransaction,
  parseDepositTransaction,
  parseFiatBankMethod,
  parseRewardTransaction,
  parseSwapTransaction,
  parseTipTransaction,
  parseWithdrawalTransaction,
} from './utils'

export const parseBalances = (data: any) => ({
  amount: data.amount,
  currency: data.currency,
  network: ChainIdEnum[formatApiNetworkField(data.network)],
})

export const BalanceMapper = (rawResponse: string): BaseResponse<Paging<BalanceResponse>> => {
  const response: BaseResponse<Paging<any>> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      paging: null,
      items: response.data.items.map((item) => parseBalances(item)),
    },
  }
}

export const TraditionalDepositMapper = (rawResponse: string): BaseResponse<{ address: string; minAmount: string }> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      address: response.data.address,
      minAmount: response.data.min_amount,
    },
  }
}

export const WithdrawInfoMapper = (rawResponse: string): BaseResponse<WithdrawInfo> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      withdrawFee: response.data.withdraw_fee,
      lockBonusAmount: response.data.deduct_amount,
      voucherId: response.data.voucher_user_id,
      depositRolloverList: response.data.wager_required
        ? response.data.wager_required.map((item) => {
            const currentUnlockAmount = new BigNumber(response.data.current_wager).minus(
              new BigNumber(item.from_amount),
            )
            return {
              token: tokens[ChainIdEnum[formatApiNetworkField(item.network)]][item.currency],
              lockAmount: new BigNumber(item.to_amount).minus(new BigNumber(item.from_amount)),
              currentUnlockAmount: currentUnlockAmount.isGreaterThan(BIG_ZERO) ? currentUnlockAmount : BIG_ZERO,
              bonusInfo: {
                bonusId: Number(item.ref),
              },
              createTime: item.create_time * 1000,
            }
          })
        : [],
    },
  }
}

export const DepositRolloverMapper = (rawResponse: string): BaseResponse<DepositRolloverBase[]> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data:
      response.data &&
      response.data.items.map((item) => ({
        bonusInfo: {
          bonusName: item?.title,
          bonusId: item.id,
        },
      })),
  }
}

export const PrepareTraditionalWithdrawMapper = (rawResponse: string): BaseResponse<VerificationResponse> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      token: response.data.verification_code,
      expireTime: response.data.otp_expire_time * 1000,
      nextResentTime: response.data.otp_next_request_time * 1000,
      withdrawLockTime: response.data.withdraw_lock_time * 1000,
    },
  }
}

export const PrepareWalletWithdrawMapper = (rawResponse: string): BaseResponse<PrepareSignMessageResponse> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      acceptMessage: response.data.accept_message,
      acceptMessageText: response.data.accept_message_text,
      address: response.data.subject,
      withdrawLockTime: response.data.withdraw_lock_time * 1000,
    },
  }
}

export const PrepareWalletTipMapper = (rawResponse: string): BaseResponse<PrepareSignMessageResponse> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      acceptMessage: response.data.accept_message,
      acceptMessageText: response.data.accept_message_text,
    },
  }
}
export const PrepareTraditionalTipMapper = (rawResponse: string): BaseResponse<VerificationResponse> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      token: response.data.verification_code,
      expireTime: response.data.otp_expire_time * 1000,
      nextResentTime: response.data.otp_next_request_time * 1000,
    },
  }
}

export const TipDetailsMapper = (rawResponse: string): BaseResponse<TipDetails> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && parseTipTransaction(response.data),
  }
}

export const SendTipMapper = (rawResponse: string): BaseResponse<TipDetails> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && parseTipTransaction(response.data),
  }
}
export const TipInfoMapper = (rawResponse: string): BaseResponse<LockAmountTipInfo> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      lockBonusAmount: response.data.deduct_amount,
      voucherId: response.data.voucher_user_id,
      depositRolloverList: response.data.wager_required
        ? response.data.wager_required.map((item) => {
            const currentUnlockAmount = new BigNumber(response.data.current_wager).minus(
              new BigNumber(item.from_amount),
            )

            return {
              token: tokens[ChainIdEnum[formatApiNetworkField(item.network)]][item.currency],
              lockAmount: new BigNumber(item.to_amount).minus(new BigNumber(item.from_amount)),
              currentUnlockAmount: currentUnlockAmount.isGreaterThan(BIG_ZERO) ? currentUnlockAmount : BIG_ZERO,
              bonusInfo: {
                bonusId: Number(item.ref),
              },
              createTime: item.create_time * 1000,
            }
          })
        : [],
    },
  }
}

export const WithdrawMapper = (rawResponse: string): BaseResponse<PrepareSignMessageResponse> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && response.data.code.toString(),
  }
}
export const WithdrawFiatMapper = (rawResponse: string): BaseResponse<FiatPaymentTransaction> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data:
      response.data &&
      ({
        amount: {
          amount: response.data.value,
          token: tokens[ChainIdEnum.FIAT][response.data.currency],
        },
        createdAt: response.data.create_time * 1000,
        txnHash: response.data.txn_hash,
        code: response.data.code,
      } as FiatPaymentTransaction),
  }
}

export const WithdrawalTransactionMapper = (rawResponse: string): BaseResponse<Transaction> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && parseWithdrawalTransaction(response.data),
  }
}

export const WithdrawalTransactionsMapper = (rawResponse: string): BaseResponse<Paging<Transaction>> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      paging: response.data.paging,
      items: response.data.items && response.data.items.map((item) => parseWithdrawalTransaction(item)),
    },
  }
}

export const DepositTransactionMapper = (rawResponse: string): BaseResponse<Transaction> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && parseDepositTransaction(response.data),
  }
}

export const BetTransactionsMapper = (rawResponse: string): BaseResponse<Paging<BetDetails>> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      paging: response.data.paging,
      items: response.data.items && response.data.items.map((item) => parseBetDetailsItemMapper(item)),
    },
  }
}

export const DepositTransactionsMapper = (rawResponse: string): BaseResponse<Paging<Transaction>> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      paging: response.data.paging,
      items: response.data.items && response.data.items.map((item) => parseDepositTransaction(item)),
    },
  }
}

export const RewardTransactionsMapper = (rawResponse: string): BaseResponse<Paging<RewardTransaction>> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      paging: response.data.paging,
      items: response.data.items && response.data.items.map((item) => parseRewardTransaction(item)),
    },
  }
}

export const BonusTransactionMapper = (rawResponse: string): BaseResponse<Paging<RewardTransaction>> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }
  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      paging: response.data.paging,
      items: response.data.items && response.data.items.map((item) => parseBonusTransaction(item)),
    },
  }
}

export const SwapTransactionMapper = (rawResponse: string): BaseResponse<Paging<SwapTransaction>> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      paging: response.data.paging,
      items: response.data.items && response.data.items.map((item) => parseSwapTransaction(item)),
    },
  }
}
export const TipTransactionMapper = (rawResponse: string): BaseResponse<Paging<TipTransaction>> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      paging: response.data.paging,
      items: response.data.items && response.data.items.map((item) => parseTipTransaction(item)),
    },
  }
}

export const GetDepositInfoByHashMapper = (rawResponse: string): BaseResponse<Transaction> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      txnHash: response.data.txn_hash,
      currency: response.data.currency,
      network: ChainIdEnum[formatApiNetworkField(response.data.network)],
      value: response.data.value,
      fee: '',
      id: -1,
      status: response.data.status,
      createTime: response.data.create_time * 1000,
      type: TransactionTypeEnum.Deposit,
      fiat: isFiatCurrencyV2(
        tokens[ChainIdEnum[formatApiNetworkField(response.data.network)]]?.[response.data.currency],
      ) &&
        response.data?.extra_data && {
          bankName: response.data.extra_data.bank_name || 'Unknown',
        },
    },
  }
}

export const QueryDepositByHashMapper = (rawResponse: string): BaseResponse<QueryDepositInfo> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      status: response.data.watch.status === -1 ? QueryDepositStatusEnums.ScanFailed : response.data.watch.status,
      depositInfo: response.data.deposit
        ? {
            currency: response.data.deposit.currency,
            network: ChainIdEnum[formatApiNetworkField(response.data.deposit.network)],
            value: response.data.deposit.value,
            status: response.data.deposit.status,
          }
        : null,
    },
  }
}

export const DepositFiatBankMethodListMapper = (
  rawResponse: string,
): BaseResponse<[string, DepositFiatPaymentMethod[]][]> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  const fiatPaymentMethods =
    response.data.items.length > 0 &&
    Object.entries(
      response.data.items.reduce((fiatPaymentMethods, paymentMethod) => {
        return {
          ...fiatPaymentMethods,
          [paymentMethod.deposit_type]: (fiatPaymentMethods[paymentMethod.deposit_type] || []).concat([
            parseFiatBankMethod(paymentMethod, 'deposit_type'),
          ]),
        }
      }, {}),
    )

  return {
    code: response.code,
    message: response.message,
    data: !response.data?.items
      ? null
      : (FiatMethodCategory.map((item) => {
          const index = fiatPaymentMethods.findIndex(([type]) => {
            return item.methods.includes(Number(type))
          })
          return index > -1 ? [fiatPaymentMethods[index][0], fiatPaymentMethods[index][1]] : []
        }) as [string, DepositFiatPaymentMethod[]][]),
  }
}

export const WithdrawFiatBankMethodListMapper = (
  rawResponse: string,
): BaseResponse<[string, WithdrawFiatPaymentMethod[]][]> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  const fiatPaymentMethods =
    response.data.items.length > 0 &&
    Object.entries(
      response.data.items.reduce((fiatPaymentMethods, paymentMethod) => {
        return {
          ...fiatPaymentMethods,
          [paymentMethod.withdrawal_type]: (fiatPaymentMethods[paymentMethod.withdrawal_type] || []).concat([
            {
              ...parseFiatBankMethod(paymentMethod, 'withdrawal_type'),
              minAmount: new BigNumber(
                new BigNumber(paymentMethod.tx_min_amount).plus(paymentMethod.withdraw_fee_amount),
              )
                .dividedBy(new BigNumber(1).minus(new BigNumber(paymentMethod.withdraw_fee_percent).dividedBy(100)))
                .toFixed(0, BigNumber.ROUND_UP)
                .toString(),
              feeAmount: paymentMethod.withdraw_fee_amount,
              feePercent: paymentMethod.withdraw_fee_percent,
            } as WithdrawFiatPaymentMethod,
          ]),
        }
      }, {}),
    )

  return {
    code: response.code,
    message: response.message,
    data: !response.data?.items
      ? null
      : (FiatMethodCategory.map((item) => {
          const index = fiatPaymentMethods.findIndex(([type]) => {
            return item.methods.includes(Number(type))
          })
          return index > -1 ? [fiatPaymentMethods[index][0], fiatPaymentMethods[index][1]] : []
        }) as [string, WithdrawFiatPaymentMethod[]][]),
  }
}

export const FiatDepositMapper = (
  rawResponse: any,
): BaseResponse<{ content: string; txnHash: string; pendingTransaction?: Transaction }> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      content: response.data.content,
      txnHash: response.data.tx_code,
      pendingTransaction: response.data?.pending_tx && parseDepositTransaction(response.data.pending_tx),
    },
  }
}
