import { DepositRolloverInfo } from 'config/types/payment'
import { useRequest } from 'hooks/useRequest'
import { useEffect, useMemo, useState } from 'react'
import PaymentService from 'services/PaymentService'

const useDepositRollover = (depositRolloverList: DepositRolloverInfo[]) => {
  const [depositRolloverListDetails, setDepositRolloverListDetails] = useState<DepositRolloverInfo[]>()
  const [isLoading, setIsLoading] = useState(false)
  const { execute } = useRequest()

  useEffect(() => {
    const fetchFee = async () => {
      setIsLoading(true)
      const bonusListID = depositRolloverList.map((item) => item.bonusInfo?.bonusId)
      const response = await execute(PaymentService.getDepositRollover(bonusListID))

      if (response?.data) {
        const depositRolloverBase = response?.data
        const depositRolloverBaseMap = new Map(depositRolloverBase.map((item) => [item.bonusInfo?.bonusId, item]))
        const depositRolloverDetails = depositRolloverList
          .filter((item: DepositRolloverInfo) => depositRolloverBaseMap.has(item.bonusInfo?.bonusId))
          .map((item) => {
            const matchedItemB = depositRolloverBaseMap.get(item.bonusInfo?.bonusId)
            return {
              ...item,
              ...matchedItemB,
            }
          })
        setDepositRolloverListDetails(depositRolloverDetails)
      }

      setIsLoading(false)
    }

    fetchFee()
  }, [depositRolloverList])

  return useMemo(
    () => ({
      depositRolloverListDetails: depositRolloverListDetails || [],
      isLoading,
    }),
    [depositRolloverListDetails, isLoading],
  )
}

export default useDepositRollover
