import { RowCenter } from 'layout/Components/Row'
import { Trans } from 'react-i18next'
import styled from 'styled-components'
import { BoxProps } from 'UIKit/Box/types'
import Text from 'UIKit/Text'

export enum AdditionalLabelEnum {
  Recent,
  Detect,
}

const BaseLabel: React.FC<{ children: React.ReactNode } & BoxProps> = ({ children, ...props }) => {
  return (
    <RowCenter height="14px" borderRadius="4px" px="4px" width="fit-content" {...props}>
      <Text width="fit-content" fontSize="8px" fontWeight={500} lineHeight="normal" letterSpacing="-0.16px">
        <Trans>{children}</Trans>
      </Text>
    </RowCenter>
  )
}

const RecentLabel: React.FC<BoxProps> = ({ ...props }) => {
  return <StyledRecentLabel {...props}>Recent</StyledRecentLabel>
}

const DetectedLabel: React.FC<BoxProps> = ({ ...props }) => {
  return <StyledDetectedLabel {...props}>Detected</StyledDetectedLabel>
}

const AdditionalLabel: React.FC<{ labelType: AdditionalLabelEnum } & BoxProps> = ({ labelType, ...props }) => {
  return (
    <>
      {labelType === AdditionalLabelEnum.Recent ? (
        <RecentLabel {...props} />
      ) : (
        labelType === AdditionalLabelEnum.Detect && <DetectedLabel {...props} />
      )}
    </>
  )
}

const StyledRecentLabel = styled(BaseLabel)`
  background-color: #413043;
  ${Text} {
    color: ${({ theme }) => theme.colors.primary};
  }
`

const StyledDetectedLabel = styled(BaseLabel)`
  background-color: ${({ theme }) => theme.colors.modalBackground};
  ${Text} {
    color: ${({ theme }) => theme.colors.textSubtle};
  }
`

export default AdditionalLabel
