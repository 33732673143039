import { useVariableLoadedListener } from 'hooks/useVariableLoadedListener'
import { useEffect, useRef } from 'react'

const NOTIFICATION_BAR_HEIGHT = 40

const useIframeUpdater = (iframeUrl: string) => {
  const refPrepareGame = useRef(null)
  const refContentGame = useRef(null)
  const refIframe = useRef(null)
  const iframeContainerRef = useRef<HTMLDivElement>(null)

  const toggleFullScreen = () => {
    if (!refIframe.current) return
    if (refIframe.current.requestFullscreen) {
      refIframe.current.requestFullscreen()
    } else if (refIframe.current.webkitRequestFullscreen) {
      refIframe.current.webkitRequestFullscreen()
    } else if (refIframe.current.mozFullScreenElement) {
      refIframe.current.mozFullScreenElement()
    } else if (refIframe.current?.msRequestFullscreen) {
      refIframe.current.msRequestFullscreen()
    }
  }

  const contentHeight = useVariableLoadedListener(() => {
    const height = refContentGame?.current?.clientHeight
    return height > 0 ? height : false
  }, [refContentGame])

  const gameInfoHeight = useVariableLoadedListener(() => {
    const height = refPrepareGame?.current?.clientHeight
    return height > 0 ? height : false
  }, [refPrepareGame])

  const rateHeightContentGame = ((contentHeight - NOTIFICATION_BAR_HEIGHT) * 0.95) / (gameInfoHeight ?? 1)
  const scale =
    !rateHeightContentGame || rateHeightContentGame > 1 || rateHeightContentGame <= 0 ? 1 : rateHeightContentGame

  useEffect(() => {
    if (iframeUrl && iframeContainerRef.current) {
      const frame = document.createElement('iframe')
      frame.src = iframeUrl
      frame.title = 'game-desktop-view'
      frame.style.width = '100%'
      frame.style.height = '100%'
      frame.allowFullscreen = true
      frame.style.inset = '0'
      iframeContainerRef.current.replaceChildren(frame)
      refIframe.current = frame
    }
  }, [iframeContainerRef.current, iframeUrl])

  return { toggleFullScreen, scale, refContentGame, refPrepareGame, refIframe, iframeContainerRef }
}

export default useIframeUpdater
