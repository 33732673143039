import { Trans } from 'react-i18next'
import Box from 'UIKit/Box/Box'
import Flex from 'UIKit/Box/Flex'
import { BoxProps } from 'UIKit/Box/types'
import Image from 'UIKit/Image'
import Text from 'UIKit/Text'

const Property: React.FC<{ children: React.ReactNode; src: string } & BoxProps> = ({ children, src, ...props }) => {
  return (
    <Flex mt="4px" {...props}>
      <Box width="14px" height="20px" minWidth="14px" minHeight="20px">
        <Image src={src} width={56} height={80} />
      </Box>
      <Text
        fontSize={['12px', '', '', '14px']}
        fontWeight={400}
        color="textSubtle"
        lineHeight={['15px', '', '', '18px']}
        ml="4px"
      >
        <Trans>{children}</Trans>
      </Text>
    </Flex>
  )
}

export default Property
