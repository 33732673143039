import Box from 'UIKit/Box/Box'
import Flex from 'UIKit/Box/Flex'
import { BoxProps } from 'UIKit/Box/types'
import Image from 'UIKit/Image/Image'
import Link from 'UIKit/Link/Link'
import { RouteConfig } from 'config/constants/route'
import React, { ReactNode } from 'react'
import { FlexProps } from 'styled-system'
import TelegramTooltipContent from './components/TelegramTooltipContent'

type SocialMediaType = {
  href: string
  id: string
  imageSrc: string
  alt?: string
  content?: ReactNode | string
  attributes?: {
    rel?: string
  }
}

const SocialMediaResourcesDefault: SocialMediaType[] = [
  {
    href: RouteConfig.Telegram,
    id: 'enterBonusCodeModal-social-telegram',
    imageSrc: '/images/bonus/telegram-icon.png',
    alt: 'Telegram',
    content: <TelegramTooltipContent />,
    attributes: {
      rel: 'nofollow',
    },
  },
  {
    href: RouteConfig.Twitter,
    id: 'enterBonusCodeModal-social-twitter',
    imageSrc: '/images/social/twitter-icon.png',
    alt: 'Twitter',
    attributes: {
      rel: 'nofollow',
    },
  },
  {
    href: RouteConfig.Discord,
    id: 'enterBonusCodeModal-social-discord',
    imageSrc: '/images/bonus/discord-icon.png',
    alt: 'Discord',
    attributes: {
      rel: 'nofollow',
    },
  },
  {
    href: RouteConfig.Reddit,
    id: 'enterBonusCodeModal-social-reddit',
    imageSrc: '/images/bonus/reddit-icon.png',
    alt: 'Reddit',
    attributes: {
      rel: 'nofollow',
    },
  },
  {
    href: RouteConfig.Facebook,
    id: 'enterBonusCodeModal-social-facebook',
    imageSrc: '/images/bonus/facebook-icon.png',
    alt: 'Facebook',
    attributes: {
      rel: 'nofollow',
    },
  },
  {
    href: RouteConfig.Instagram,
    id: 'enterBonusCodeModal-social-instagram',
    imageSrc: '/images/bonus/instagram-icon.png',
    alt: 'Instagram',
    attributes: {
      rel: 'nofollow',
    },
  },
  {
    href: RouteConfig.Youtube,
    id: 'enterBonusCodeModal-social-youtube',
    imageSrc: '/images/bonus/youtube-icon.png',
    alt: 'Youtube',
    attributes: {
      rel: 'nofollow',
    },
  },
]

const DisplaySocialMedia: React.FC<
  { socialMediaResources?: SocialMediaType[]; iconSize?: number } & FlexProps & BoxProps
> = ({ socialMediaResources = SocialMediaResourcesDefault, iconSize = 32, ...props }) => {
  return (
    <Flex alignItems="center" justifyContent="center" {...props}>
      {socialMediaResources.map(({ href, id, imageSrc, alt, content, attributes = {} }) => {
        return (
          <React.Fragment key={id}>
            {content ? (
              React.isValidElement(content) ? (
                React.cloneElement(content, {
                  // @ts-ignore
                  href,
                  id,
                  imageSrc,
                  iconSize,
                  alt,
                  ...(attributes || {}),
                })
              ) : (
                <>{content}</>
              )
            ) : (
              <Link mx="4px" href={href} id={id} external {...(attributes || {})}>
                <Box width={iconSize} height={iconSize}>
                  <Image src={imageSrc} alt={alt} width={96} height={96} />
                </Box>
              </Link>
            )}
          </React.Fragment>
        )
      })}
    </Flex>
  )
}

export default DisplaySocialMedia
