import Box from 'UIKit/Box/Box'
import { BoxProps } from 'UIKit/Box/types'
import Text from 'UIKit/Text'
import { WalletInfo } from 'config/types/wallet'
import styled from 'styled-components'
import { getIcon } from 'svgs'
import Column from 'layout/Components/Column'
import AdditionalLabel, { AdditionalLabelEnum } from './AdditionalLabel'

interface WalletCardProps {
  wallet: WalletInfo
  onSelect: (wallet: WalletInfo) => void
  isRecentWallet: boolean
  isDetected: boolean
}

const WalletCard: React.FC<WalletCardProps & BoxProps> = ({
  wallet,
  onSelect,
  isRecentWallet,
  isDetected,
  ...props
}) => {
  const Icon = getIcon(wallet.icon)
  return (
    <StyledContainer {...props} onClick={() => onSelect(wallet)}>
      <Icon width="28px" />
      <Column ml="8px">
        <Text fontSize={['10px', '', '', '12px']} fontWeight="500" lineHeight="normal" letterSpacing="-0.24px">
          {wallet.name}
        </Text>
        <AdditionalLabel
          labelType={isRecentWallet ? AdditionalLabelEnum.Recent : isDetected ? AdditionalLabelEnum.Detect : null}
          mt="4px"
        />
      </Column>
    </StyledContainer>
  )
}

const StyledContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 16px;

  max-height: 49px;

  background: rgba(255, 255, 255, 0.04);
  border-radius: 40px;
  box-sizing: border-box;
  cursor: pointer;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`

export default WalletCard
