import useModal from 'hooks/useModal'
import { useUserBonus } from 'state/bonus/hooks'
import ClaimBonusModal from '.'

const useClaimBonusModal = () => {
  const [onPresentClaimBonusModal, onDismissClaimBonusModal] = useModal(ClaimBonusModal)
  const { fetchBonuses } = useUserBonus()

  const handleOpenClaimBonusModal = () => {
    onPresentClaimBonusModal({
      onClaimed: fetchBonuses,
    })
  }

  return { handleOpenClaimBonusModal, onDismissClaimBonusModal }
}

export default useClaimBonusModal
