import Box from 'UIKit/Box/Box'
import Flex from 'UIKit/Box/Flex'
import Dropdown from 'UIKit/Dropdown'
import { DropdownProps } from 'UIKit/Dropdown/types'
import Image from 'UIKit/Image'
import Link from 'UIKit/Link'
import Text from 'UIKit/Text'
import Column from 'layout/Components/Column'
import React from 'react'
import { Trans } from 'react-i18next'
import styled from 'styled-components'

const StyledWrapper: React.FC<React.PropsWithChildren<DropdownProps>> = ({
  children,
  dropdownContent,
  dropdownPosition,
  style,
  id,
  maxWidth,
  ...props
}) => {
  return (
    <Box
      position="relative"
      maxWidth={maxWidth}
      display="flex"
      id={id}
      style={{
        cursor: 'pointer',
        ...style,
      }}
      className="wrapper"
    >
      <Dropdown
        display="flex"
        style={{
          flexDirection: 'column',
        }}
        dropdownContent={dropdownContent}
        dropdownPosition={dropdownPosition}
        {...props}
      >
        <Flex alignItems="center" justifyContent="center" mx="4px">
          {children}
        </Flex>
      </Dropdown>
    </Box>
  )
}

const TelegramContent: React.FC = () => {
  return (
    <StyledDropdownContainer>
      <Column>
        <Box mb="4px">
          <Text fontSize="14px" fontWeight={600} lineHeight="18px" color="text">
            <Trans>Channel</Trans>:
          </Text>
        </Box>

        <Box>
          <Link href="https://t.me/HunnyFinanceNews" mb="4px" external>
            <Box width={24} height={24} mr="4px">
              <Image src="/images/channel-hunnyplay-icon.png" height={100} width={100} alt="logo-hunny" />
            </Box>

            <StyledText>
              <Trans>HunnyPlay Channel</Trans>
            </StyledText>
          </Link>

          <Link href="https://t.me/hunnyplay_vip_notice" mb="4px" external>
            <Box width={24} height={24} mr="4px">
              <Image src="/images/channel-vipclub-icon.png" width={100} height={100} alt="logo-vip-club" />
            </Box>
            <StyledText>
              <Trans>VIP Club</Trans>
            </StyledText>
          </Link>

          <Link href="https://t.me/hunnyplaybonusdrop" mb="4px" external>
            <Box width={24} height={24} mr="4px">
              <Image src="/images/channel-bonus-drop-icon.png" height={100} width={100} alt="logo-bonus-drop" />
            </Box>
            <StyledText>
              <Trans>Bonus Drop</Trans>
            </StyledText>
          </Link>

          <Link href="https://t.me/Hunny_Daily_Challenge" external>
            <Box width={24} height={24} mr="4px">
              <Image
                src="/images/channel-daily-challenge-icon.png"
                width={100}
                height={100}
                alt="logo-daily-challenge"
              />
            </Box>
            <StyledText>
              <Trans>Daily Challenge</Trans>
            </StyledText>
          </Link>
        </Box>

        <Box>
          <Box my="4px">
            <Text fontSize="14px" fontWeight={600} lineHeight="18px" color="text">
              <Trans>Chat</Trans>:
            </Text>
          </Box>

          <Box>
            <Link href="https://t.me/HunnyPlay" mb="4px" external>
              <Box width={24} height={24} mr="4px">
                <Image src="/images/main-chat-icon.png" height={100} width={100} alt="logo-main-chat" />
              </Box>
              <StyledText>
                <Trans>Main Chat</Trans>
              </StyledText>
            </Link>
          </Box>
        </Box>
      </Column>
    </StyledDropdownContainer>
  )
}

interface TelegramTooltipContentProps {
  href?: string
  id?: string
  imageSrc?: string
  iconSize?: string
  alt?: string
}

const TelegramTooltipContent: React.FC<TelegramTooltipContentProps> = (props) => {
  return (
    <StyledWrapper dropdownContent={<TelegramContent />} dropdownPosition="top-left">
      <Box width={props.iconSize} height={props.iconSize}>
        <Image src={props.imageSrc} alt={props.alt} width={96} height={96} />
      </Box>
    </StyledWrapper>
  )
}

const StyledText = styled(Text)`
  color: ${({ theme }) => theme.colors.textSubtle};
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;

  &:hover {
    color: ${({ theme }) => theme.colors.text};
  }
`

const StyledDropdownContainer = styled(Box)`
  background: #2b2e36;
  position: relative;
  display: flex;
  min-width: 204px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  padding: 8px 12px;
  border-radius: 6px;
  margin-bottom: 12px;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    bottom: -2%;
    left: 6%;
    width: 10px;
    height: 10px;
    transform: rotate(135deg);
    border-radius: 1px;
    background: #2b2e36;
    z-index: -2;
  }
`

export default TelegramTooltipContent
