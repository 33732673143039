import { Trans } from 'react-i18next'
import Box from 'UIKit/Box/Box'
import Flex from 'UIKit/Box/Flex'
import { BoxProps, FlexProps } from 'UIKit/Box/types'
import Text from 'UIKit/Text'

const InformationDetails: React.FC<FlexProps & BoxProps & { title: string; icon: React.ReactNode }> = ({
  title,
  icon,
  children,
  ...props
}) => {
  return (
    <Flex mb="20px" {...props}>
      <Box minWidth="24px" maxWidth="24px">
        {icon}
      </Box>
      <Box ml="12px">
        <Text
          fontSize={['14px', '', '16px']}
          fontWeight={700}
          color="textAlt3"
          letterSpacing={['-0.28px', '', '-0.32px']}
          lineHeight="20px"
          className="information-title"
        >
          <Trans>{title}</Trans>
        </Text>
        {children}
      </Box>
    </Flex>
  )
}

export default InformationDetails
