import { PublicKey } from '@solana/web3.js'
import { Token } from 'config/types'
import { ChainIdEnum } from '../network'

const path = '/images/token'

const tokens: Record<string, Token> = {
  SOL: {
    coinGeckoInfo: {
      id: 'solana',
    },
    logo: `${path}/sol.png`,
    name: 'SOL',
    code: 'SOL',
    network: ChainIdEnum.SOL,
    isNative: true,
    address: new PublicKey('dead111111111111111111111111111111111111111').toBase58(),
    decimals: 9,
  },
  USDC: {
    coinGeckoInfo: {
      id: 'usd-coin',
    },
    logo: `${path}/usdc.png`,
    name: 'USDC',
    code: 'USDC',
    network: ChainIdEnum.SOL,
    address: new PublicKey('EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v').toBase58(),
    decimals: 6,
    isStable: true,
  },
  USDT: {
    coinGeckoInfo: {
      id: 'tether',
    },
    logo: `${path}/usdt.png`,
    name: 'USDT',
    code: 'USDT',
    network: ChainIdEnum.SOL,
    address: 'Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB',
    decimals: 6,
    isStable: true,
  },
}

export default tokens
