import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { useMemo } from 'react'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import app, { AppState as SiteState } from './app'
import auth, { AuthState } from './auth'
import bonus, { BonusState } from './bonus'
import cashback, { CashbackState } from './cashback'
import notification, { NotificationState } from './notification'
import profile, { ProfileState } from './profile'
import session, { SessionState } from './session'
import system, { SystemState } from './system'
import transaction, { TransactionState } from './transaction'

const PERSISTED_KEYS: string[] = ['auth', 'session', 'transaction']

const persistConfig = {
  key: 'hunnyplay',
  whitelist: PERSISTED_KEYS,
  storage,
}

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    app,
    profile,
    auth,
    system,
    transaction,
    notification,
    bonus,
    cashback,
    session,
  }),
)

// eslint-disable-next-line import/no-mutable-exports
let store: ReturnType<typeof makeStore>

function makeStore(preloadedState = undefined) {
  return configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: true,
        serializableCheck: {
          ignoredActions: [
            FLUSH,
            REHYDRATE,
            PAUSE,
            PERSIST,
            PURGE,
            REGISTER,
            'cashback/updateAvailableCashbacks',
            'app/initMetadata',
            'bonus/updateUserBonuses',
            'app/setCurrencyConversionRateInUSD',
            'bonus/updateDailyReward',
            'profile/updateUserSetting',
            'profile/initialProfile',
            'app/updateWagerAdventure',
          ],
          ignoredPaths: ['profile', 'bonus', 'app', 'cashback'],
        },
      }),
    devTools: process.env.NODE_ENV === 'development',
    preloadedState,
  })
}

export const initializeStore = (preloadedState = undefined) => {
  let _store = store ?? makeStore(preloadedState)

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && store) {
    _store = makeStore({
      ...store.getState(),
      ...preloadedState,
    })
    // Reset the current store
    store = undefined
  }

  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') return _store

  // Create the store once in the client
  if (!store) {
    store = _store
  }

  return _store
}

store = initializeStore()

/**
 * @see https://redux-toolkit.js.org/usage/usage-with-typescript#getting-the-dispatch-type
 */
export type AppState = {
  app: SiteState
  profile: ProfileState
  auth: AuthState
  system: SystemState
  transaction: TransactionState
  notification: NotificationState
  bonus: BonusState
  cashback: CashbackState
  session: SessionState
  isDisplayCommitment: boolean
}

export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector

export default store

export const persistor = persistStore(store)

export function useStore(initialState) {
  return useMemo(() => initializeStore(initialState), [initialState])
}
