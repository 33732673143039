import { DepositRolloverInfo } from 'config/types/payment'
import { ColumnCenter } from 'layout/Components/Column'
import styled from 'styled-components'
import Box from 'UIKit/Box/Box'
import Modal, { ModalBody, ModalHeader, ModalProps } from 'UIKit/ModalV2'
import Text from 'UIKit/Text'
import TransText from 'UIKit/Text/TransText'
import useDepositRollover from '../useDepositRollover'
import CardDetails from './CardDetails'
import CardLoader from './CardLoader'

type DepositRolloverProps = {
  depositRolloverList: DepositRolloverInfo[]
}

const DepositRolloverModal: React.FC<ModalProps<DepositRolloverProps>> = ({ data: { depositRolloverList } }) => {
  const { depositRolloverListDetails, isLoading } = useDepositRollover(depositRolloverList)

  return (
    <Modal p={['16px 20px', '', '', '24px 24px 12px 24px']}>
      <StyledHeader title="Deposit Rollover" minHeight="auto" />
      <ModalBody>
        <StyledContainer>
          <TransText
            color="textSubtle"
            fontSize="14px"
            fontWeight={500}
            lineHeight="normal"
            letterSpacing="-0.28px"
            my="20px"
          >
            Deposits made to activate certain special bonuses require wagering the deposit amount before withdrawal to
            prevent fraud. You can withdraw after wagering 1x the deposit amount.
          </TransText>

          <ColumnCenter width="100%" maxHeight="424px" overflowY="scroll" mb="-20px">
            {isLoading ? (
              <CardLoader />
            ) : (
              <>
                {depositRolloverListDetails.map((item, index) => (
                  <CardDetails
                    depositRolloverDetails={item}
                    key={index}
                    mb="20px"
                    width={['100%', '', '380px !important']}
                  />
                ))}
              </>
            )}
          </ColumnCenter>
        </StyledContainer>
      </ModalBody>
    </Modal>
  )
}

const StyledContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 24px;
`

const StyledHeader = styled(ModalHeader)`
  ${Text} {
    font-weight: 700;
  }
`

export default DepositRolloverModal
