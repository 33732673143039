import BigNumber from 'bignumber.js'
import { ChainIdEnum } from 'config/constants/network'
import { BIG_ZERO } from 'config/constants/number'
import { HUSD_TOKEN } from 'config/constants/tokens'
import { Token } from 'config/types'
import { DepositRolloverInfo } from 'config/types/payment'
import { useRequest } from 'hooks/useRequest'
import { useEffect, useMemo, useState } from 'react'
import PaymentService from 'services/PaymentService'
import { useAppSelector } from 'state'
import { formatToApiNetworkField, getChainCode } from 'utils'
import { isTonChain, isXrpChain } from 'utils/network'
import { getNetworkCodeType } from 'utils/token'
import useDepositRolloverModal from '../../DepositRollover/DepositRolloverModal/useDepositRolloverModal'

export const useWithdrawInfo = (selectedToken: Token) => {
  const minWithdrawTokenAmounts = useAppSelector((state) => state.app.minWithdrawTokenAmounts)
  const selectedTokenMinWithdrawAmount = minWithdrawTokenAmounts[selectedToken.code]

  const { execute } = useRequest()
  const [withdrawFee, setWithdrawFee] = useState<string>(null)
  const [widthrawLockedAmount, setWidthrawLockedAmount] = useState<{
    lockAmount: BigNumber
    bonusId?: number
  }>({ lockAmount: BIG_ZERO })
  const [depositRolloverList, setDepositRolloverList] = useState<DepositRolloverInfo[]>([])

  useEffect(() => {
    if (selectedToken === HUSD_TOKEN) return

    const fetchFee = async () => {
      const response = await execute(
        PaymentService.getWithdrawInfo({
          chainType: getNetworkCodeType(selectedToken.network),
          chainCode: getChainCode(ChainIdEnum[selectedToken.network]),
          currency: selectedToken.code,
        }),
      )

      if (response?.code === 'success') {
        setWithdrawFee(response.data.withdrawFee)
        setWidthrawLockedAmount({
          lockAmount: new BigNumber(response.data.lockBonusAmount || 0),
          bonusId: response.data.voucherId || null,
        })
        setDepositRolloverList(response.data.depositRolloverList)
        return
      }

      if (depositRolloverList.length > 0) setDepositRolloverList([])
      if (widthrawLockedAmount.lockAmount.isGreaterThan(BIG_ZERO)) setWidthrawLockedAmount({ lockAmount: BIG_ZERO })
    }

    fetchFee()
  }, [selectedToken])

  return useMemo(() => {
    const { lockAmount, bonusId } = widthrawLockedAmount

    return {
      withdrawFee: new BigNumber(withdrawFee),
      selectedTokenMinWithdrawAmount,
      lockAmount,
      bonusId,
      depositRolloverList,
    }
  }, [withdrawFee, widthrawLockedAmount, selectedTokenMinWithdrawAmount, depositRolloverList])
}

export const useWithdrawAddressNotAllowed = (
  selectedToken: Token,
): { addressNotAllowed: string; isFetching: boolean } => {
  const tokenInfoes = useAppSelector((state) => state.app.tokenInfoes)
  const [addressNotAllowed, setAddressNotAllowed] = useState('')
  const [isFetching, setIsFetching] = useState(false)

  const { execute } = useRequest()

  useEffect(() => {
    if (!selectedToken || selectedToken === HUSD_TOKEN) return

    const getAddressNotAllowed = async () => {
      setIsFetching(true)

      if (isTonChain(selectedToken.network) || isXrpChain(selectedToken.network)) {
        const spender = tokenInfoes?.find(
          (tokenInfo) => tokenInfo.network === selectedToken.network && tokenInfo.token === selectedToken.code,
        )?.contractAddress

        if (spender) setAddressNotAllowed(spender)
      } else {
        const response = await execute(
          PaymentService.traditionalDeposit(
            {
              currency: selectedToken.code,
              network: formatToApiNetworkField(ChainIdEnum[selectedToken.network]),
            },
            true,
          ),
        )
        if (response?.data?.address) setAddressNotAllowed(response?.data?.address)
      }

      setIsFetching(false)
    }

    getAddressNotAllowed()
  }, [selectedToken, tokenInfoes])

  return useMemo(() => {
    return {
      addressNotAllowed,
      isFetching,
    }
  }, [addressNotAllowed, isFetching])
}

export const useDepositRolloverOverview = (depositRolloverList: DepositRolloverInfo[]) => {
  const [presentDepositRolloverModal] = useDepositRolloverModal()

  const handleOpenDepositRolloverModal = () => {
    presentDepositRolloverModal({ depositRolloverList })
  }

  const totalLockAmount = useMemo(() => {
    return depositRolloverList.reduce((total, item) => total.plus(item.lockAmount), BIG_ZERO)
  }, [depositRolloverList])

  return useMemo(
    () => ({
      openDepositRollovermodal: handleOpenDepositRolloverModal,
      totalDepositRollover: totalLockAmount,
    }),
    [totalLockAmount, handleOpenDepositRolloverModal],
  )
}
