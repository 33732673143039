import useModal from 'hooks/useModal'
import DepositRolloverModal from '.'

const DEPOSIT_ROLLOVER_MODAL_KEY = 'deposit-rollover-modal'

const useDepositRolloverModal = () => {
  const modal = useModal(DepositRolloverModal, DEPOSIT_ROLLOVER_MODAL_KEY)
  return modal
}

export default useDepositRolloverModal
