import { RouteConfig } from 'config/constants/route'
import { useCloseAllModal } from 'hooks/useModal'
import { ColumnCenter } from 'layout/Components/Column'
import { RowMiddle } from 'layout/Components/Row'
import { useState } from 'react'
import { Trans } from 'react-i18next'
import styled from 'styled-components'
import { colors } from 'theme/colors'
import Box from 'UIKit/Box/Box'
import { BoxProps } from 'UIKit/Box/types'
import Button from 'UIKit/Button'
import NextLinkFromReactRouter from 'UIKit/NextLink'
import Text from 'UIKit/Text'
import TransText from 'UIKit/Text/TransText'
import { formatRoute } from 'utils/stringHelper'
import { ReferralTabEnums } from 'views/Referral/types'

enum BenefitEnum {
  EveryOne,
  NewUser,
}

const WELCOME_BONUS_SLUG = '/welcome-bonus---play-with-bonus-instantly'
const ABOUT_REFERRAL = `${RouteConfig.Referral}?tab=${ReferralTabEnums.ABOUT_REFERRAL}`

const BenefitOverview: React.FC<BoxProps> = ({ ...props }) => {
  const [activeTab, setActiveTab] = useState(BenefitEnum.EveryOne)
  const closeModal = useCloseAllModal()

  const handleChangeTag = (tab: BenefitEnum) => {
    setActiveTab(tab)
  }

  const isForNewUser = activeTab === BenefitEnum.NewUser

  const promotionsWelcomeBonusURL = formatRoute(RouteConfig.PromotionDetail, {
    promotionSlug: WELCOME_BONUS_SLUG,
  })

  return (
    <Box {...props}>
      <RowMiddle>
        <StyledTextBtn onClick={() => handleChangeTag(BenefitEnum.EveryOne)}>
          <TransText
            fontSize="12px"
            fontWeight={!isForNewUser ? 600 : 400}
            color={!isForNewUser ? 'textTertiary' : 'textSubtle'}
          >
            You can earn
          </TransText>
        </StyledTextBtn>

        <Box mx="4px" minHeight="12px" minWidth="1px" background={colors.textSubtle} />

        <StyledTextBtn onClick={() => handleChangeTag(BenefitEnum.NewUser)}>
          <TransText
            fontSize="12px"
            fontWeight={isForNewUser ? 600 : 400}
            color={isForNewUser ? 'textTertiary' : 'textSubtle'}
          >
            For new user
          </TransText>
        </StyledTextBtn>
      </RowMiddle>

      <RowMiddle p="12px" mt="8px" border="0.5px solid #12B76A" borderRadius="8px" background="rgba(0, 0, 0, 0.20)">
        <ColumnCenter width="50%" minWidth="50%" borderRight="1px solid  #575b65">
          <Text color="#12B76A" fontSize="20px" lineHeight="140%" fontWeight={700} letterSpacing="-0.4px">
            {isForNewUser ? '300%' : ' $5000 + 18%'}
          </Text>
          <Text fontSize="12px" fontWeight="500" color="#12B76A" lineHeight="140%" letterSpacing="-0.24px">
            <Trans>{isForNewUser ? 'Deposit Bonus' : 'Commission'}</Trans>
          </Text>
        </ColumnCenter>

        <Text color="textSubtle" fontSize="12px" ml="12px" letterSpacing="-0.24px" lineHeight="normal" fontWeight={500}>
          <Trans>
            {isForNewUser
              ? 'Grab a 300% deposit bonus and play with your bonus instantly!'
              : '$5000 Bonus & Lifetime commission on every referral'}
          </Trans>
          <StyledTextBtn onClick={closeModal}>
            <StyledRedirectText
              as={NextLinkFromReactRouter}
              to={isForNewUser ? promotionsWelcomeBonusURL : ABOUT_REFERRAL}
              color="textSubtle"
            >
              <Trans>Details</Trans>
            </StyledRedirectText>
          </StyledTextBtn>
        </Text>
      </RowMiddle>
    </Box>
  )
}

const StyledTextBtn = styled(Button)`
  padding: 0px !important;
  height: fit-content;
  background: none;

  &:hover:not(:disabled):not(:active) {
    transition: none;
    transform: none;
    ${Text} {
      color: ${({ theme: { colors } }) => colors.textTertiary};
      opacity: 0.8;
    }
  }
`

const StyledRedirectText = styled(Text)`
  text-decoration: underline;
  margin-left: 6px;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.24;
`

export default BenefitOverview
