import useModal from 'hooks/useModal'
import { COMMITMENTS_MODAL_KEY } from './config'
import CommitmentsModal from '.'

const useCommitmentsModal = () => {
  const modal = useModal(CommitmentsModal, COMMITMENTS_MODAL_KEY)
  return modal
}

export default useCommitmentsModal
