import BigNumber from 'bignumber.js'
import Box from 'UIKit/Box/Box'
import Button from 'UIKit/Button/Button'
import Image from 'UIKit/Image/Image'
import CircleLoader from 'UIKit/Loader/CircleLoader'
import Modal, { ModalBody, ModalProps } from 'UIKit/Modal'

import { ChainIdEnum } from 'config/constants/network'
import tokens, { HUSD_TOKEN } from 'config/constants/tokens'
import { Token, TokenAmount } from 'config/types'
import { useBalanceSelect } from 'layout/Components/BalanceSelect/hooks'
import { RowCenter } from 'layout/Components/Row'
import { useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useAppSelector } from 'state'
import { useAllBlacklistPlayTokens, useAllTokens, useListNetworks, useTokenUsdPrice } from 'state/app/hooks'
import { useTokenSelected } from 'state/session/hooks'
import styled from 'styled-components'
import Text from 'UIKit/Text/Text'
import { getBalanceTokenKey } from 'utils'
import { getFullDisplayBalance } from 'utils/formatBalance'
import PaymentTokenSelect from 'views/Payment/components/PaymentTokenSelect'

const useSwapBonusInfo = (isCreaditBonus: boolean) => {
  const playToken = useTokenSelected()

  const swapableTokens = useAllTokens(
    (token: Token, _, __, info) =>
      !info.disabledPlay && (isCreaditBonus ? token.code !== 'LOVE' && token.code !== 'HUNNY' : true),
    [],
  )

  const networkCodes = useMemo(() => swapableTokens.map((t) => t.network), [swapableTokens])
  const networks = useListNetworks({ includeFiat: true, selectChains: networkCodes })

  const initialToken = useMemo(() => {
    if (!swapableTokens.includes(playToken)) return swapableTokens[0]

    return playToken
  }, [])

  return useMemo(() => ({ initialToken, networks }), [initialToken, networks])
}

const SwapBonusModal: React.FC<
  ModalProps<{
    bonusAmountInUsd: BigNumber
    isCreaditBonus?: boolean
    onSubmit: (amount: TokenAmount) => Promise<boolean>
  }>
> = ({ onDismiss, data }) => {
  const { bonusAmountInUsd, onSubmit, isCreaditBonus } = data
  const tokenBalances = useAppSelector((state) => state.profile.balances)
  const [submitting, setSubmitting] = useState(false)
  const { t } = useTranslation()

  const blackListToken = useAllBlacklistPlayTokens()

  const { initialToken, networks } = useSwapBonusInfo(isCreaditBonus)
  const { balanceOptions } = useBalanceSelect(initialToken)

  const swapableToken = useMemo(() => {
    if (initialToken === HUSD_TOKEN) {
      const highestBalanceToken = balanceOptions.find((item) => item.balance.token !== HUSD_TOKEN)

      if (highestBalanceToken) return highestBalanceToken.balance.token
      return tokens[ChainIdEnum.BNB]['BNB']
    }

    return initialToken
  }, [initialToken, balanceOptions])
  const [selectedToken, setSelectedToken] = useState<Token>(swapableToken)

  const priceInUsd = useTokenUsdPrice(selectedToken)

  const fetchTokenBalanceFn = useCallback(
    async (token: Token) => {
      return Promise.resolve(new BigNumber(tokenBalances[getBalanceTokenKey(token.network, token.code)] || 0))
    },
    [tokenBalances],
  )

  const handleClaimBonus = async () => {
    setSubmitting(true)
    const isSucceed = await onSubmit({
      amount: bonusAmountInUsd.div(priceInUsd),
      token: selectedToken,
    })
    if (isSucceed) {
      onDismiss()
    }
    setSubmitting(false)
  }

  return (
    <Modal
      onDismiss={onDismiss}
      minWidth={['', '', '', '', '408px !important']}
      minHeight={['', '', '', '', '558px !important']}
    >
      <ModalBody p="24px" overflow="hidden !important">
        <RowCenter>
          <Box width="120px" height="120px">
            <Image src="/images/bonus/swap-bonus-icon.png" width={240} height={240} />
          </Box>
        </RowCenter>

        <Box my={20}>
          <Text fontSize="20px" fontWeight={700} maxWidth={360} mx="auto" textAlign="center">
            <Trans>Choose currency to receive bonus</Trans>
          </Text>
          <Text
            color="textSubtle"
            fontSize="12px"
            fontWeight={300}
            letterSpacing={-0.24}
            lineHeight={'16px'}
            mx="auto"
            textAlign="justify"
            maxWidth={340}
            mt="6px"
          >
            <Trans>
              Please select the currency in which you like to receive your bonus. The system will convert the bonus
              amount (in USD) to your chosen currency and credit it to your main balance
            </Trans>
          </Text>
        </Box>

        <PaymentTokenSelect
          token={selectedToken}
          onTokenSelect={setSelectedToken}
          fetchTokenBalanceFn={fetchTokenBalanceFn}
          networks={networks}
          dropdownHeight="140px"
          hideHUSDToken={isCreaditBonus}
          selectToken={(token: Token) => {
            return (
              !blackListToken.includes(token) &&
              (isCreaditBonus ? token.code !== 'LOVE' && token.code !== 'HUNNY' : true)
            )
          }}
          isIncludeFiat
        />

        <Text fontSize="12px" fontWeight={400} color="textAlt" mt="6px">
          <Trans>Estimated ~{{ amount: getFullDisplayBalance(bonusAmountInUsd.div(priceInUsd), 0, 6) }}</Trans>

          <Text as="span" color="textSubtle" fontWeight="inherit" fontSize="inherit" ml={1}>
            {selectedToken.name}
          </Text>
        </Text>

        <StyledButton
          minWidth="280px !important"
          mt={['min(25%,122px)', , , '132px']}
          mx="auto"
          onClick={handleClaimBonus}
          disabled={submitting}
        >
          {submitting && (
            <Box mr="12px">
              <CircleLoader />
            </Box>
          )}
          <Text fontWeight={600} fontSize="14px" letterSpacing={-0.28}>
            {t('Take my bonus')}
          </Text>
        </StyledButton>
      </ModalBody>
    </Modal>
  )
}

const StyledButton = styled(Button)`
  border-radius: ${({ theme }) => theme.radii.tiny} !important;
  min-height: 43px;
`

export default SwapBonusModal
