import styled from 'styled-components'
import { colors } from 'theme/colors'
import Box from 'UIKit/Box/Box'
import Progress from 'UIKit/Progress'
import { ProgressBar } from 'UIKit/Progress/StyledProgress'
import Skeleton from 'UIKit/Skeleton'

const CardLoader: React.FC = () => {
  return (
    <StyledCardDetails width={['100%', '', '380px !important']}>
      <Skeleton width="100%" height={13} minHeight="13px !important" />
      <Skeleton width="100%" height={18} mt="4px" minHeight="18px !important" />
      <Skeleton width="100%" height={15} my="8px" minHeight="15px !important" />
      <Box mt="8px" color="#fff">
        <StyledProgress
          scale={'md'}
          primaryStep={0}
          showProgress={false}
          barStyles={{
            background: `${colors.success} !important`,
          }}
          isFull={false}
        />
      </Box>

      <Skeleton width="100%" height={15} mt="4px" minHeight="15px !important" />
    </StyledCardDetails>
  )
}

const StyledCardDetails = styled(Box)`
  width: 100%;
  padding: 12px;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors.textSubtle};
  backdrop-filter: blur(4.449999809265137px);
  background-image: url('/images/payment/deposit-rollover-card-bg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: 1px solid rgba(173, 173, 173, 0.2);
  margin-bottom: 20px;
`
const StyledProgress = styled(Progress)<{ isFull: boolean }>`
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%), #6c727e !important;

  ${ProgressBar} {
    position: unset;
    border-top-right-radius: ${({ isFull }) => (isFull ? '32px' : '0px')};
    border-bottom-right-radius: ${({ isFull }) => (isFull ? '32px' : '0px')};
  }
  height: 6px !important;
  background: rgba(35, 44, 61, 0.8) !important;
  padding: 1px;
  margin-bottom: 4px;
`

export default CardLoader
