import BigNumber from 'bignumber.js'
import { BIG_ZERO } from 'config/constants/number'
import { HUSD_TOKEN } from 'config/constants/tokens'
import { Token } from 'config/types'
import { DepositRolloverInfo } from 'config/types/payment'
import { useRequest } from 'hooks/useRequest'
import { useEffect, useMemo, useState } from 'react'
import PaymentService from 'services/PaymentService'

export const useWithdrawInfo = (selectedToken: Token) => {
  const { execute } = useRequest()
  const [widthrawLockedAmount, setWidthrawLockedAmount] = useState<{
    lockAmount: BigNumber
    bonusId?: number
  }>({ lockAmount: BIG_ZERO })
  const [depositRolloverList, setDepositRolloverList] = useState<DepositRolloverInfo[]>([])

  useEffect(() => {
    if (selectedToken === HUSD_TOKEN) return

    const fetchFee = async () => {
      const response = await execute(PaymentService.getWithdrawFiatLockBonusBalanceInfo(selectedToken.code))
      if (response?.code === 'success') {
        setWidthrawLockedAmount({
          lockAmount: new BigNumber(response.data.lockBonusAmount || 0),
          bonusId: response.data.voucherId || null,
        })
        setDepositRolloverList(response.data.depositRolloverList)
        return
      }
      if (depositRolloverList.length > 0) setDepositRolloverList([])
      if (widthrawLockedAmount.lockAmount.isGreaterThan(BIG_ZERO)) setWidthrawLockedAmount({ lockAmount: BIG_ZERO })
    }

    fetchFee()
  }, [selectedToken])

  return useMemo(() => {
    const { lockAmount, bonusId } = widthrawLockedAmount

    return {
      lockAmount,
      bonusId,
      depositRolloverList,
    }
  }, [widthrawLockedAmount, depositRolloverList])
}
