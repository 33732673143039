import OpenEffect from 'UIKit/OpenEffect'
import { Token } from 'config/types'
import styled from 'styled-components'
import TipSendForm from './components/TipSendForm'

const Tip: React.FC<{ initialToken?: Token; userName: string; isAcceptTip: boolean; onDismiss: () => void }> = ({
  initialToken,
  userName,
  isAcceptTip,
  onDismiss,
}) => {
  return (
    <StyledContainer id="tip-payment" openType="grow" duration={0.4}>
      <TipSendForm initialToken={initialToken} userName={userName} isAcceptTip={isAcceptTip} onDismiss={onDismiss} />
    </StyledContainer>
  )
}

const StyledContainer = styled(OpenEffect)`
  display: flex;
  flex-direction: column;
`

export default Tip
