import { RouteConfig } from 'config/constants/route'
import Column from 'layout/Components/Column'
import { RowCenter } from 'layout/Components/Row'
import styled from 'styled-components'
import Box from 'UIKit/Box/Box'
import Image from 'UIKit/Image'
import Modal, { ModalBody, ModalHeader, ModalProps } from 'UIKit/ModalV2'
import { Icons } from 'svgs'
import InformationDetails from 'views/Commitments/components/InformationDetails'
import HighlightText from 'views/Commitments/components/HighlightText'
import OpenLinkBtn from 'views/Commitments/components/OpenLinkBtn'
import CommitmentsTabInfo from './CommitmentsTabInfo'

const CommitmentsModal: React.FC<ModalProps> = ({ onDismiss }) => {
  return (
    <StyledModal id="commitment-modal" onDismiss={onDismiss} p="0px">
      <Column height="100%" px={['20px', '', '', '24px']} pb="36px" pt="24px">
        <ModalHeader alignItems="center" minHeight="24px" my="0px" />

        <StyledBody height="100%" pt="0px">
          <RowCenter justifyContent="center" width="100%" pb={['16px', '', '', '12px']} mt="12px">
            <Box minWidth="92px" maxWidth="92px">
              <Icons.ProtectClound height="92px" />
            </Box>
          </RowCenter>

          <InformationDetails
            icon={<Image src="/images/gift-box-3d.png" width={72} height={72} />}
            title="How is HunnyPlay able to offer higher bonuses & rewards than others?"
            pt="0px"
            mt="12px"
            mb="32px"
          >
            <HighlightText translateKey="hunnyPlayTechnology" mt="8px" />
          </InformationDetails>

          <StyledInformationDetails
            icon={<Image src="/images/gold-crown.png" width={72} height={72} />}
            title="Offering exclusive VIP experience with bigger rewards"
            mb="32px"
          >
            <HighlightText translateKey="vipBenefit" my="8px" />

            <OpenLinkBtn href={RouteConfig.BonusCenter}>Learn more</OpenLinkBtn>
          </StyledInformationDetails>

          <CommitmentsTabInfo />
        </StyledBody>
      </Column>
    </StyledModal>
  )
}

const StyledInformationDetails = styled(InformationDetails)`
  a {
    display: block;
    width: fit-content;
  }
`

const StyledModal = styled(Modal)`
  box-sizing: border-box;
  background: ${({ theme: { colors } }) => colors.modalBackground};

  border-radius: 16px;

  height: max(calc(var(--screen-height) - 20vh), min(820px, calc(var(--screen-height) - 40px)));

  & .modal-closebutton {
    right: 12px;
    top: 12px;
  }

  ${({ theme: { mediaQueries } }) => mediaQueries.sm} {
    min-width: 600px;
    max-width: 600px;
    border-radius: ${({ theme }) => theme.radii.modal};
    height: max(calc(var(--screen-height) - 20vh), min(760px, calc(var(--screen-height) - 40px)));
  }
`

const StyledBody = styled(ModalBody)`
  overflow-y: scroll;
  box-sizing: border-box;
  padding-inline: 20px;
  margin-inline: -20px;
  width: calc(100% + 40px);

  ${({ theme: { mediaQueries } }) => mediaQueries.sm} {
    width: calc(100% + 48px);
    padding-inline: 24px;
    margin-inline: -24px;
  }

  &::-webkit-scrollbar {
    width: 3px !important;
  }
`

export default CommitmentsModal
