import BigNumber from 'bignumber.js'
import DepositedActiveBonusModal from 'components/DepositedActiveBonusModal'
import ErrorModal from 'components/ErrorModal'
import FreespinBonusModal from 'components/FreespinBonusModal'
import { FixedMilestonePoint } from 'config/constants'
import { ChainIdEnum } from 'config/constants/network'
import { BIG_ZERO } from 'config/constants/number'
import { RouteConfig } from 'config/constants/route'
import tokens from 'config/constants/tokens'
import { AirdropBox, TokenAmount } from 'config/types'
import {
  ApiBonusTypeEnums,
  BonusStatusEnums,
  BonusStatusFilterEnums,
  BonusTagEnums,
  FreeHUSDLockCommonBonus,
  NoWageringCommonCashBonus,
  WageringBonusStatusEnums,
} from 'config/types/bonus'
import {
  ApiBonusStatusEnums,
  BoosterWagerBonus,
  CashBonus,
  DepositBonus,
  DepositFreespinBonus,
  FreeHUSDLockBonus,
  FreeLuckyspinBonus,
  FreespinBonus,
  HUSDUnLockBoosterBonus,
  LevelUpBonus,
  NoWageringCashBonus,
  UserBonus,
  WageringConditionBonus,
} from 'config/types/bonus/userBonus'
import { UserWelcomePack } from 'config/types/bonus/welcomePackage'
import { useAnalytics } from 'hooks/useAnalytics'
import { useIsomorphicEffect } from 'hooks/useIsomorphicEffect'
import useModal from 'hooks/useModal'
import { usePrivateSocket } from 'hooks/usePrivateSocket'
import { useRequest } from 'hooks/useRequest'
import { useRouter } from 'hooks/useRouter'
import { useUserTotalBalance } from 'hooks/useUserTotalBalance'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import BonusService from 'services/BonusService'
import { useAppDispatch, useAppSelector } from 'state'
import { useAuth } from 'state/auth/hooks'
import { useUpdateUserLuckySpin } from 'state/profile/hooks'
import { buildPluralizeText, delayed } from 'utils'
import { freeSpinBonusFiatReward, isReachMilestone } from 'utils/bonus'
import { getFullDisplayBalance } from 'utils/formatBalance'
import { forkjoinRequest } from 'utils/requestHelper'
import { HunnyToast, showToast } from 'utils/toastify'
import SwapBonusModal from 'views/BonusCenter/components/SwapBonusModal'
import WarningDepositBonusModal from 'views/BonusCenter/components/WarningDepositBonusModal'
import { useFreespinGamesModal } from 'views/BonusCenter/hooks'
import MilestoneReachSuccessToast from 'views/GameDetails/GameScreenBouns/BonusItem/components/MilestoneReachSuccessToast'
import Payment from 'views/Payment'
import {
  unWatchedNewBonuses,
  updateAirdropBoxStorage,
  updateBonusToActiveWagering,
  updateCurrentWagerInActivatedBonuses,
  updateReceivedAirdropSummary,
  updateUserBonuses,
  updateUserClaimableHUSDAmount,
  updateUserHUSDBonus,
  updateUserUnlockableHUSDAmount,
} from '../actions'
import { useFreeSpinPlayGameToken } from './freeSpinPlayGameToken'

export const useHUSDLockBonus = (pollingData?: boolean) => {
  const { isSigned } = useAuth()
  const { fetchHUSDClaimableBalance } = useUpdateHUSDBonus()
  const { claimableBalance, extraClaimableBalance, unlockableBalance } = useAppSelector(
    (state) => state.bonus.HUSDBonus,
  )
  useEffect(() => {
    if (!isSigned) return
    fetchHUSDClaimableBalance()
  }, [isSigned])

  useEffect(() => {
    if (!isSigned || !pollingData) return

    const interval = setInterval(fetchHUSDClaimableBalance, 30000)

    return () => {
      clearInterval(interval)
    }
  }, [isSigned, pollingData])
  return useMemo(() => {
    return {
      claimableBalance: new BigNumber(claimableBalance),
      extraClaimableBalance: new BigNumber(extraClaimableBalance),
      unlockableBalance: new BigNumber(unlockableBalance),
    }
  }, [claimableBalance, extraClaimableBalance, unlockableBalance])
}

export const useRedeemAirdrop = () => {
  const { fetchHUSDUnlockableBalance } = useUpdateHUSDBonus()
  const { fetchData } = useAirdropHistory()

  const redeemAirdrop = useCallback(
    async (
      airdropBoxes: AirdropBox[],
      animatedFn?: () => Promise<void>,
      callback?: (airdropBoxes: AirdropBox[]) => Promise<void> | void,
    ) => {
      let validatedBoxes = airdropBoxes
      if (airdropBoxes.length > 1) {
        const response = await BonusService.getAirdropList().call()
        const airdropBoxStorage = response?.data || []

        validatedBoxes = airdropBoxes.filter((airdropBox) =>
          airdropBoxStorage.find((airdropBoxInStorage) => airdropBoxInStorage.code === airdropBox.code),
        )
      }
      const res = await BonusService.ClaimAirdrop(validatedBoxes.map((item) => item.code))

      if (animatedFn) await animatedFn()
      if (callback) await callback(res.data ? validatedBoxes : [{ amount: '0', code: '0' }])
      await fetchHUSDUnlockableBalance()
      await fetchData()
    },
    [],
  )

  return redeemAirdrop
}

export const useAirdropBoxStorage = () => {
  const dispatch = useAppDispatch()
  const { execute } = useRequest()
  const { airdropBoxStorage } = useAppSelector((state) => state.bonus.HUSDBonus)
  const currentBoxesRef = useRef([])

  useIsomorphicEffect(() => {
    currentBoxesRef.current = airdropBoxStorage
  }, [airdropBoxStorage])

  const fetchData = useCallback(async () => {
    const response = await execute(BonusService.getAirdropList())
    if (response?.data) {
      updateData(response.data)
    }
  }, [])

  const updateData = useCallback((data: AirdropBox[]) => {
    dispatch(updateAirdropBoxStorage({ airdropBoxStorage: data }))
  }, [])

  const pushNewBox = useCallback((data: AirdropBox) => {
    dispatch(updateAirdropBoxStorage({ airdropBoxStorage: [...currentBoxesRef.current, data] }))
  }, [])

  return useMemo(
    () => ({
      airdropBoxStorage,
      totalBalance: airdropBoxStorage.reduce((result, current) => result.plus(current.amount), new BigNumber(0)),
      boxAmount: airdropBoxStorage.length,
      fetchData,
      updateData,
      pushNewBox,
    }),
    [airdropBoxStorage, fetchData, updateData, pushNewBox],
  )
}

export const useAirdropHistory = () => {
  const dispatch = useAppDispatch()
  const { execute } = useRequest()
  const { totalReceivedAirdrop, totalAmount } = useAppSelector((state) => state.bonus.HUSDBonus.receivedAirdropSummary)

  const fetchData = useCallback(async () => {
    const response = await execute(BonusService.getReceivedAirdropSummary())
    if (response?.data) {
      dispatch(updateReceivedAirdropSummary({ receivedAirdropSummary: response?.data }))
    }
  }, [])

  return useMemo(
    () => ({
      fetchData,
      totalReceivedAirdrop,
      totalAmount,
    }),
    [fetchData, totalReceivedAirdrop, totalAmount],
  )
}

export const useHUSDLockBalance = () => {
  const { unlockableBalance } = useAppSelector((state) => state.bonus.HUSDBonus)

  return useMemo(() => {
    return new BigNumber(unlockableBalance)
  }, [unlockableBalance])
}

export const useUpdateHUSDBonus = () => {
  const { execute } = useRequest()
  const dispatch = useAppDispatch()

  const fetchHUSDBonusBalance = useCallback(async () => {
    const [claimBalanceRes, lockBalanceRes, bonusBonusRes] = await forkjoinRequest([
      execute(BonusService.getHUSDClaimableAmount()),
      execute(BonusService.getHUSDLockAmount()),
      execute(BonusService.getExtraHUSDBonusAmount()),
    ])

    const extraClaimableHUSD = new BigNumber(bonusBonusRes || 0)
    const claimableHUSD = new BigNumber(claimBalanceRes?.data?.amount || BIG_ZERO)
    const unlockableHUSD = new BigNumber(lockBalanceRes?.data || BIG_ZERO)

    dispatch(
      updateUserHUSDBonus({
        claimableBalance: claimableHUSD.toString(10),
        unlockableBalance: unlockableHUSD.toString(10),
        extraClaimableBalance: extraClaimableHUSD.toString(10),
      }),
    )
  }, [])

  const fetchHUSDClaimableBalance = useCallback(async () => {
    const [claimBalanceRes, bonusBonusRes] = await forkjoinRequest([
      execute(BonusService.getHUSDClaimableAmount()),
      execute(BonusService.getExtraHUSDBonusAmount()),
    ])

    const extraClaimableHUSD = new BigNumber(bonusBonusRes || 0)
    const claimableHUSD = new BigNumber(claimBalanceRes?.data?.amount || BIG_ZERO)

    dispatch(
      updateUserClaimableHUSDAmount({
        claimableBalance: claimableHUSD.toString(10),
        extraClaimableBalance: extraClaimableHUSD.toString(10),
      }),
    )
  }, [])

  const fetchHUSDUnlockableBalance = useCallback(async () => {
    const lockBalanceRes = await execute(BonusService.getHUSDLockAmount())

    const unlockableHUSD = new BigNumber(lockBalanceRes?.data || BIG_ZERO)

    dispatch(
      updateUserUnlockableHUSDAmount({
        unlockableBalance: unlockableHUSD.toString(10),
      }),
    )
  }, [])

  return { fetchHUSDBonusBalance, fetchHUSDClaimableBalance, fetchHUSDUnlockableBalance }
}

export const useActivatedBonuses = () => {
  const { bonuses } = useUserBonus()

  const activatedBonuses = useMemo(() => {
    return bonuses.filter((bonus) => bonus.status === BonusStatusEnums.Active)
  }, [bonuses])

  return { activatedBonuses: activatedBonuses || [] }
}

export const useUnwatchedNewBonusListener = (): [boolean, () => void] => {
  const unwatchedNewBonus = useAppSelector((state) => state.bonus.unwatchedNewBonus)
  const { isSigned } = useAuth()
  const dispatch = useAppDispatch()

  const onCloseBadge = () => {
    dispatch(
      unWatchedNewBonuses({
        unwatchedNewBonuses: null,
      }),
    )
  }

  useEffect(() => {
    if (unwatchedNewBonus && !isSigned) {
      onCloseBadge()
    }
  }, [isSigned])

  return [!!unwatchedNewBonus, onCloseBadge]
}

export const useUpdateUnwatchedNewBonus = () => {
  const dispatch = useAppDispatch()
  const socket = usePrivateSocket()
  const { isSigned } = useAuth()
  const { fetchBonuses } = useUserBonus()

  useEffect(() => {
    if (!isSigned || !socket) {
      return
    }

    socket.on('voucher.rewarded', (data) => {
      if (data) {
        dispatch(
          unWatchedNewBonuses({
            unwatchedNewBonuses: data,
          }),
        )
        fetchBonuses()
      }
    })

    return () => {
      if (socket) {
        socket.off('voucher.rewarded')
      }
    }
  }, [isSigned, socket])
}

export const useUserBonus = () => {
  const dispatch = useAppDispatch()
  const bonuses = useAppSelector((state) => state.bonus.userBonuses)
  const { execute } = useRequest()

  const fetchBonuses = useCallback(async () => {
    const result = await execute(
      BonusService.getUserBonuses(0, 100, {
        status: BonusStatusFilterEnums.Available,
      }),
    )

    if (result?.data?.items) {
      dispatch(
        updateUserBonuses({
          userBonuses: result?.data?.items,
        }),
      )
    }

    return result
  }, [dispatch])

  return { bonuses: bonuses || [], fetchBonuses }
}

export const useActivatedBonusesUpdater = () => {
  const { isSigned } = useAuth()
  const { activatedBonuses } = useActivatedBonuses()
  const { fetchBonuses } = useUserBonus()
  const activeBonusInWageringProcess = useActiveBonusInWageringProcess()
  const socket = usePrivateSocket()
  const dispatch = useAppDispatch()

  const [presentDepositedActiveBonusModal] = useModal(DepositedActiveBonusModal)
  const [presentFreespinBonusModal] = useModal(FreespinBonusModal)
  const { t } = useTranslation()

  const { recordEvent } = useAnalytics()

  useEffect(() => {
    if (!activatedBonuses.length) return
    const timeouts = activatedBonuses
      .map((item) => {
        const maxTimeSetTimeOutAllowance = 2147483647
        let timeLeft = item.condition.expiryDate - Date.now()
        if (timeLeft < 0) return null
        if (timeLeft > maxTimeSetTimeOutAllowance) timeLeft = maxTimeSetTimeOutAllowance

        return setTimeout(() => {
          fetchBonuses()
        }, timeLeft)
      })
      .filter((item) => item)

    return () => {
      timeouts.forEach((timeout) => clearTimeout(timeout))
    }
  }, [activatedBonuses])

  useEffect(() => {
    if (!isSigned || !activeBonusInWageringProcess || !socket) {
      return
    }

    socket.on('voucher.bet.collection', async (data) => {
      // bonus.status == 10 is present wager completed
      if (data.status == 10 && activeBonusInWageringProcess.isCreaditReward) {
        recordEvent('finish_bonus', {
          type: ApiBonusTypeEnums[activeBonusInWageringProcess.type],
          isClaimToPlayBonus: activeBonusInWageringProcess.isCreaditReward,
        })

        HunnyToast.showNoti(
          t("🎉 Congratulations! You've completed the bonus wagering. The bonus is now in your real balance."),
          {
            type: 'alerts',
          },
        )

        setTimeout(async () => {
          await fetchBonuses()
        }, 500)
      }
      const currentWager = activeBonusInWageringProcess.isCreaditReward
        ? data.current_wager_currency
        : data.current_wager_usd

      const newProgress =
        Math.floor(
          new BigNumber(currentWager)
            .div(activeBonusInWageringProcess.targetWagerBigAmount)
            .multipliedBy(10000)
            .toNumber(),
        ) / 100

      if (
        Math.floor(Number(newProgress) / 10) === Math.ceil(activeBonusInWageringProcess.progress / 10) &&
        Math.floor(Number(newProgress) / 10) !== Math.floor(activeBonusInWageringProcess.progress / 10)
      ) {
        recordEvent('wagering_bonus', {
          type: ApiBonusTypeEnums[activeBonusInWageringProcess.type],
          isClaimToPlayBonus: activeBonusInWageringProcess.isCreaditReward,
          progress: Math.floor(newProgress),
        })
      }

      if (activeBonusInWageringProcess?.isActiveMilestone) {
        const activeMilestone = isReachMilestone(activeBonusInWageringProcess, newProgress)
        if (activeMilestone && !activeMilestone?.isReached) {
          const response = await fetchBonuses()

          if (response?.data?.items) {
            response.data.items.forEach((bonusItem) => {
              if (
                bonusItem instanceof WageringConditionBonus &&
                bonusItem.status === BonusStatusEnums.Active &&
                bonusItem.isActiveMilestone
              ) {
                bonusItem.reachedMilestoneDetails?.map((milestone) => {
                  if (
                    milestone.wagerPercentage === activeMilestone.wagerPercentage &&
                    milestone.isReached !== activeMilestone.isReached
                  )
                    showToast(<MilestoneReachSuccessToast />)
                  return
                })
              }
            })
          }
        }
      }

      dispatch(
        updateCurrentWagerInActivatedBonuses({
          currentWager,
          id: data.voucher_user_id,
        }),
      )
    })
    return () => {
      if (socket) {
        socket.off('voucher.bet.collection')
      }
    }
  }, [isSigned, socket, activeBonusInWageringProcess])

  useEffect(() => {
    if (!isSigned || !socket) {
      return
    }

    socket.on('voucher.freeround.close', async (data) => {
      const bonusAmount = new BigNumber(data.value_currency)
      const res = await BonusService.getUserBonusDetails(data.voucher_user_id).call()
      const bonus = res.data as DepositFreespinBonus

      if (!bonus) return
      fetchBonuses()
      presentFreespinBonusModal({ bonus, onSubmitted: () => {}, isZeroAmount: bonusAmount.eq(0) })
      recordEvent('finish_freespin', {
        rewardInUsd: data.value_currency,
        freespinAmount: bonus.freeSpinAmount,
      })
    })

    return () => {
      if (socket) {
        socket.off('voucher.freeround.close')
      }
    }
  }, [isSigned, socket])

  useEffect(() => {
    const wageringBonus = activatedBonuses.find(
      (bonus) =>
        bonus instanceof WageringConditionBonus &&
        bonus.wageringBonusStatus === WageringBonusStatusEnums.InProgressWagering,
    )

    if (!activeBonusInWageringProcess && wageringBonus) {
      const submit = async () => {
        const bonusId = await BonusService.reselectWageringBonus()
        if (bonusId) {
          dispatch(updateBonusToActiveWagering({ id: bonusId }))
        }
      }

      submit()
    }
  }, [activatedBonuses, !!activeBonusInWageringProcess])

  useEffect(() => {
    if (!isSigned || !socket) {
      return
    }

    socket.on('voucher.deposit.confirmed', async (data) => {
      const res = await BonusService.getUserBonusDetails(data.voucher_user_id).call()
      const bonus = res.data
      if (!bonus) return

      fetchBonuses()
      presentDepositedActiveBonusModal({ bonus })
    })

    return () => {
      if (socket) {
        socket.off('voucher.deposit.confirmed')
      }
    }
  }, [isSigned, socket])
}

export const useActiveLevelUpBonus = (): LevelUpBonus => {
  const { activatedBonuses } = useActivatedBonuses()
  const userLevel = useAppSelector((state) => state.profile.tier?.level || 1)

  return useMemo(() => {
    const levelupBonus = activatedBonuses.find((bonus) => bonus instanceof LevelUpBonus) as LevelUpBonus

    if (levelupBonus) {
      if (userLevel > levelupBonus.maxLevels) {
        return new LevelUpBonus(levelupBonus, {
          duration: levelupBonus.duration,
          extraLevels: 0,
          maxLevels: levelupBonus.maxLevels,
        })
      }
      if (userLevel + levelupBonus.extraLevels > levelupBonus.maxLevels) {
        return new LevelUpBonus(
          levelupBonus,

          {
            duration: levelupBonus.duration,
            extraLevels: levelupBonus.maxLevels - userLevel,
            maxLevels: levelupBonus.maxLevels,
          },
        )
      }

      return levelupBonus
    }
    return null
  }, [activatedBonuses, userLevel])
}

export const useActiveHUSDBoosterBonus = (): HUSDUnLockBoosterBonus => {
  const { activatedBonuses } = useActivatedBonuses()

  return useMemo(
    () => activatedBonuses.find((bonus) => bonus instanceof HUSDUnLockBoosterBonus),
    [activatedBonuses],
  ) as HUSDUnLockBoosterBonus
}

export const useExtraHUSDUnlockBonus = () => {
  const { isSigned } = useAuth()
  const { execute } = useRequest()
  const [amount, setAmount] = useState(null)

  const fetchAmount = useCallback(async () => {
    const result = await execute(BonusService.getExtraHUSDBonusAmount())

    if (result) {
      setAmount(result)
    }
  }, [])

  useEffect(() => {
    if (!isSigned) return
    fetchAmount()
  }, [isSigned])

  return { amount, fetchAmount }
}

export enum BonusActionTypeEnums {
  Deposit = 'deposit',
  Active = 'active',
  Register = 'resgister',
  Use = 'use',
  Spin = 'spin',
  Play = 'play',
  Claim = 'claim',
  AddBonusLock = 'add',
}

export const useBonusAction = (bonus: UserBonus) => {
  const { fetchBonuses } = useUserBonus()
  const { fetchHUSDUnlockableBalance } = useUpdateHUSDBonus()
  const [presentPaymentModal] = useModal(Payment)
  const [presentErrorModal] = useModal(ErrorModal)
  const [presentFreespinGamesModal] = useFreespinGamesModal(bonus)

  const [handlePresentSwapBonusModal] = useModal(SwapBonusModal)
  const [handlePresentWarningDeposit] = useModal(WarningDepositBonusModal)

  const { recordEvent } = useAnalytics()
  const router = useRouter()
  const { isSigned } = useAuth()
  const update = useUpdateUserLuckySpin()
  const [submitting, setSubmitting] = useState(false)
  const { t } = useTranslation()
  const userBalance = useUserTotalBalance()
  const isZeroBalance = userBalance.isEqualTo(0)
  const bonusWagering = useActiveBonusInWageringProcess()
  const freeSpinPlayGameToken = useFreeSpinPlayGameToken(bonus)

  const actionType = useMemo(() => {
    if (!bonus) return null
    if (bonus.status === BonusStatusEnums.Available) {
      if (bonus instanceof WageringConditionBonus) {
        if (bonus.wageringBonusStatus === WageringBonusStatusEnums.WaitingDeposit) {
          return BonusActionTypeEnums.Deposit
        }

        if (bonus instanceof CashBonus && !bonus.isCreaditReward) {
          return BonusActionTypeEnums.AddBonusLock
        }

        if (bonus.wageringBonusStatus === WageringBonusStatusEnums.Available) {
          if (bonus.apiStatus === ApiBonusStatusEnums.WaitFRBResister) return BonusActionTypeEnums.Register
        }
      }

      if (bonus.tags.includes(BonusTagEnums.CanActive)) {
        return BonusActionTypeEnums.Active
      }

      return BonusActionTypeEnums.Use
    }

    if (bonus.status === BonusStatusEnums.Active) {
      if (bonus instanceof WageringConditionBonus) {
        if (bonus.wageringBonusStatus === WageringBonusStatusEnums.WaitingDeposit) {
          return BonusActionTypeEnums.Deposit
        }

        if (bonus.wageringBonusStatus === WageringBonusStatusEnums.WaitingSpin) {
          return BonusActionTypeEnums.Spin
        }

        if (
          (bonus instanceof FreespinBonus || bonus instanceof DepositFreespinBonus) &&
          bonus.isCreaditReward &&
          bonus.wageringBonusStatus === WageringBonusStatusEnums.CreditRewardWaitingRedeem
        ) {
          return BonusActionTypeEnums.Claim
        }

        if (bonus.wageringBonusStatus === WageringBonusStatusEnums.InProgressWagering) {
          if (!bonus.isCreaditReward && bonus.progress >= 100) {
            return BonusActionTypeEnums.Claim
          }

          if (bonus.progress < 100) {
            const reachedMilestone = isReachMilestone(bonus)
            if (reachedMilestone && !reachedMilestone.isClaimed) return BonusActionTypeEnums.Claim
          }

          return isZeroBalance ? BonusActionTypeEnums.Deposit : BonusActionTypeEnums.Play
        }
      }
    }

    return null
  }, [bonus?.status, (bonus as any)?.wageringBonusStatus, bonus, isZeroBalance])

  const actionContent = useMemo(() => {
    switch (actionType) {
      case BonusActionTypeEnums.Active:
        return 'Active now'
      case BonusActionTypeEnums.Register:
        return 'Active now'
      case BonusActionTypeEnums.AddBonusLock:
        return 'Active now'
      case BonusActionTypeEnums.Deposit:
        return 'Deposit now'
      case BonusActionTypeEnums.Play:
        return 'Play games'
      case BonusActionTypeEnums.Spin:
        return 'Make spins'
      case BonusActionTypeEnums.Use: {
        if (bonus instanceof FreeLuckyspinBonus) return 'Use spins'
        if (
          bonus instanceof FreeHUSDLockBonus ||
          bonus instanceof FreeHUSDLockCommonBonus ||
          bonus instanceof NoWageringCashBonus ||
          bonus instanceof NoWageringCommonCashBonus
        )
          return 'Claim now'
        return 'Use now'
      }

      case BonusActionTypeEnums.Claim:
        if (bonus instanceof WageringConditionBonus && bonus.isActiveMilestone) return 'Claim reward'
        return 'Claim bonus'
      default:
        return null
    }
  }, [actionType])

  const submit = useCallback(async () => {
    if (!isSigned || !actionType) return

    switch (actionType) {
      case BonusActionTypeEnums.Register:
        setSubmitting(true)
        const isSucceed = await BonusService.registerFreespinBonus(bonus.id)
        if (isSucceed) {
          await fetchBonuses()
        }
        setSubmitting(false)
        return true

      case BonusActionTypeEnums.Deposit:
        setSubmitting(true)
        if (bonusWagering && (bonus as DepositBonus).isCreaditReward) {
          handlePresentWarningDeposit()
        } else {
          await BonusService.prepareDepositBonus(bonus.id)
          presentPaymentModal()
        }

        setSubmitting(false)
        return true

      case BonusActionTypeEnums.Spin:
        if (bonus instanceof DepositFreespinBonus && bonus.apiStatus === ApiBonusStatusEnums.WaitFRBResister) {
          setSubmitting(true)
          const isSucceed = await BonusService.registerFreespinBonus(bonus.id)
          if (isSucceed) {
            await fetchBonuses()
          }

          setSubmitting(false)
        }

        presentFreespinGamesModal(freeSpinPlayGameToken || null)
        break

      case BonusActionTypeEnums.Play:
        router.push({
          pathname: RouteConfig.Games,
          query: { gameCollection: (bonus as WageringConditionBonus).gameCollectionId },
        })
        return true

      case BonusActionTypeEnums.Claim:
        const reachedMilestones = (bonus as WageringConditionBonus)?.reachedMilestoneDetails
          ?.map((milestone) => (milestone.isReached && !milestone.isClaimed ? milestone : null))
          ?.filter((item) => item)
        const milestoneClaimableAmount = reachedMilestones.reduce(
          (totalClaimed, milestone) => milestone.bonusAmount.plus(totalClaimed),
          new BigNumber(0),
        )
        const wageringBonus = bonus as unknown as WageringConditionBonus

        const claimBonus = async (tokenAmount?: TokenAmount) => {
          setSubmitting(true)
          const res = await BonusService.claim(
            bonus.id,
            wageringBonus?.milestoneClaimToken ? wageringBonus.milestoneClaimToken : tokenAmount?.token,
            [
              ...new Set(
                reachedMilestones
                  .map((milestone) => milestone.code)
                  .concat(
                    [
                      bonus instanceof WageringConditionBonus && !bonus.isActiveMilestone && bonus.progress === 100
                        ? FixedMilestonePoint
                        : '',
                    ].filter((code) => code),
                  ),
              ),
            ],
          )
          if (res.code === 'success') {
            await delayed(1000)

            if (isReachMilestone(bonus)) {
              HunnyToast.success(
                t("Bonus's milestone claimed."),
                t('You have claimed {{amount}} {{currency}} milestone', {
                  amount: getFullDisplayBalance(milestoneClaimableAmount, 0, 5),
                  currency: wageringBonus?.bonusAmount?.token?.name,
                }),
              )
            } else {
              HunnyToast.success(
                t('Successful!'),
                t('{{amount}} {{token}} has been added to your balance', {
                  amount: getFullDisplayBalance(tokenAmount?.amount || wageringBonus?.bonusAmount?.amount, 0, 6),
                  token: tokenAmount?.token?.name || wageringBonus?.bonusAmount?.token?.name,
                }),
              )
            }

            await fetchBonuses()

            if (bonus instanceof WageringConditionBonus && !bonus.isCreaditReward) {
              recordEvent('finish_bonus', {
                type: ApiBonusTypeEnums[bonus.type],
                isClaimToPlayBonus: false,
              })
            }

            setSubmitting(false)
            return true
          }

          if (res.code === 'error_data_exists' && bonus instanceof WageringConditionBonus && bonus.isCreaditReward) {
            presentErrorModal({
              title: 'You currently have an Active Bonus',
              buttonContent: 'I Understand',
              desc: 'Before you can activate your new bonus, you must first cancel your current active bonus',
              buttonHandle: () => {},
            })
          }

          setSubmitting(false)
          return false
        }

        if (freeSpinBonusFiatReward(bonus)) {
          return claimBonus({
            ...(bonus as any).bonusAmount,
            token: tokens[ChainIdEnum.FIAT]?.[(bonus as any).freeSpinCurrency],
          })
        }

        if (
          (bonus instanceof FreespinBonus || bonus instanceof DepositFreespinBonus) &&
          ((bonus.isActiveMilestone && !bonus.milestones.some((milestone) => milestone.isClaimed)) ||
            !bonus.isActiveMilestone)
        ) {
          handlePresentSwapBonusModal({
            bonusAmountInUsd:
              (milestoneClaimableAmount.isGreaterThan(0) && milestoneClaimableAmount) || bonus.bonusAmount.amount,
            onSubmit: claimBonus,
            isCreaditBonus: bonus.isCreaditReward,
          })
          return true
        }

        return claimBonus(wageringBonus?.isActiveMilestone && wageringBonus.bonusAmount)

      case BonusActionTypeEnums.AddBonusLock:
        setSubmitting(true)
        const isAddSucceed = await BonusService.addBonusLock(bonus.id)
        if (isAddSucceed) {
          await fetchBonuses()
        }
        setSubmitting(false)
        return true

      default:
        setSubmitting(true)

        const [result] = await forkjoinRequest([
          bonus instanceof WageringConditionBonus && bonus.isCreaditReward
            ? BonusService.claim(bonus.id)
            : BonusService.apply(bonus.id),
          delayed(700),
        ])

        if (result.code === 'success') {
          if (bonus instanceof FreeLuckyspinBonus) {
            HunnyToast.success(
              t('Successful!'),
              t(
                buildPluralizeText('You have received {{amount}} lucky {{spin}}', [
                  {
                    number: bonus.amount,
                    key: 'spin',
                    word: ['spin', 'spins'],
                  },
                ]),
                { amount: bonus.amount },
              ),
            )
            await update()
          }

          if (bonus instanceof LevelUpBonus) {
            HunnyToast.success(
              t('Successful!'),
              t(
                buildPluralizeText('You have received {{extraLevels}} VIP {{level}} in {{time}} {{day}}', [
                  {
                    number: bonus.extraLevels,
                    key: 'level',
                    word: ['level', 'levels'],
                  },
                  {
                    number: bonus.duration,
                    key: 'day',
                    word: ['day', 'days'],
                  },
                ]),
                { extraLevels: bonus.extraLevels, time: bonus.duration },
              ),
            )
          }

          if (bonus instanceof FreeHUSDLockBonus) {
            fetchHUSDUnlockableBalance()
            HunnyToast.success(
              t('Successful!'),
              t('You have received {{amount}} locked HUSD', { amount: bonus.amount }),
            )
          }

          if (bonus instanceof HUSDUnLockBoosterBonus) {
            HunnyToast.success(
              t('Successful!'),
              t(
                buildPluralizeText('You have received {{percent}}% HUSD unlock booster in {{time}} {{day}}', [
                  {
                    number: bonus.duration,
                    key: 'day',
                    word: ['day', 'days'],
                  },
                ]),
                { percent: bonus.extraUnlockPercent, time: bonus.duration },
              ),
            )
          }

          if (bonus instanceof NoWageringCashBonus) {
            HunnyToast.success(
              t('Successful!'),
              t('You have received {{amount}} {{tokenCode}}', {
                amount: bonus.bonusAmount.amount,
                tokenCode: bonus.bonusAmount.token.code,
              }),
            )
          }
          await fetchBonuses()
          setSubmitting(false)
          return true
        }
        setSubmitting(false)

        if (result.code === 'error_invalid_data') {
          HunnyToast.error(
            t('Failed!'),
            t("You don't meet the conditions of the promotion or the promotion has ended!"),
          )
          fetchBonuses()
          return false
        }

        if (result.code === 'error_data_exists') {
          presentErrorModal({
            title: 'You currently have an Active Bonus',
            buttonContent: 'I Understand',
            desc: 'Before you can activate your new bonus, you must first cancel your current active bonus',
            buttonHandle: () => {},
          })
          return false
        }
        HunnyToast.error(t('Failed!'), t("You don't meet the conditions of the promotion or the promotion has ended!"))
        return false
    }
  }, [isSigned, bonus, actionType, bonusWagering, freeSpinPlayGameToken])

  return { submit, submitting, actionContent, actionType }
}

export const useActiveBonusInWageringProcess = (): WageringConditionBonus => {
  const activatedBonuses = useAppSelector((state) => state.bonus.userBonuses)

  return useMemo(() => {
    return activatedBonuses.find(
      (bonus) =>
        bonus instanceof WageringConditionBonus &&
        bonus.wageringBonusStatus === WageringBonusStatusEnums.InProgressWagering &&
        bonus.isActiveWagering,
    ) as WageringConditionBonus
  }, [activatedBonuses])
}

export const useAllWageringBonusesHaveAmount = (): WageringConditionBonus[] => {
  const activatedBonuses = useAppSelector((state) => state.bonus.userBonuses)

  return useMemo(() => {
    return activatedBonuses.filter(
      (bonus) => bonus instanceof WageringConditionBonus && !bonus.isCreaditReward && bonus.bonusAmount?.amount.gt(0),
    ) as WageringConditionBonus[]
  }, [activatedBonuses])
}

export const useWelcomePackage = () => {
  const welcomePackage = useAppSelector((state) => state.app.welcomePackage)

  return { welcomePackage }
}

export const useUserWelcomePackage = (): UserWelcomePack => {
  const welcomePackage = useAppSelector((state) => state.app.welcomePackage)
  const welcomePackExpiredAt = useAppSelector((state) => state.profile.welcomePackExpiredAt)
  const isClaimed = useAppSelector((state) => state.profile.hasClaimedWelcomePackage)

  return useMemo(() => {
    return {
      ...welcomePackage,
      isClaimed,
      expiredAt: new Date(welcomePackExpiredAt),
    }
  }, [welcomePackage, welcomePackExpiredAt])
}

export const useOnBonusFinishListener = () => {
  const socket = usePrivateSocket()
  const { isSigned } = useAuth()
  const { fetchBonuses } = useUserBonus()
  const { t } = useTranslation()

  useEffect(() => {
    if (!isSigned || !socket) {
      return
    }

    socket.on('voucher.preloaded.auto.cancel', (data) => {
      if (data) {
        HunnyToast.success(
          t('Finish!'),
          t('The active bonus has been finished because all of the bonus amount has been used.'),
          {
            autoClose: 8000,
          },
        )
        fetchBonuses()
      }
    })

    return () => {
      if (socket) {
        socket.off('voucher.preloaded.auto.cancel')
      }
    }
  }, [isSigned, socket])
}

export const useActiveBoosterWagerBonus = (): BoosterWagerBonus => {
  const activatedBonuses = useAppSelector((state) => state.bonus.userBonuses)

  return useMemo(() => {
    return activatedBonuses.find(
      (bonus) =>
        bonus instanceof BoosterWagerBonus &&
        bonus.status === BonusStatusEnums.Active &&
        bonus.boostedAmount !== bonus.maxBooster,
    ) as BoosterWagerBonus
  }, [activatedBonuses])
}

export const useOnBoostWagerBonusListener = () => {
  const socket = usePrivateSocket()
  const { isSigned } = useAuth()
  const { fetchBonuses } = useUserBonus()
  const { t } = useTranslation()

  useEffect(() => {
    if (!isSigned || !socket) {
      return
    }

    socket.on('voucher.booster.wager.completed', (data) => {
      if (data) {
        HunnyToast.success(
          t('Completed'),
          t('Your booster has been completed as you have reached the maximum boostable wager.'),
        )
        fetchBonuses()
      }
    })

    return () => {
      if (socket) {
        socket.off('voucher.booster.wager.completed')
      }
    }
  }, [isSigned, socket])
}
