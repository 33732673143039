import Box from 'UIKit/Box/Box'
import Flex from 'UIKit/Box/Flex'
import Carousel from 'UIKit/Carousel'
import CarouseLinkItem from 'UIKit/Carousel/CarouseLinkItem'
import { StyledPaginationContainer } from 'UIKit/Carousel/Carousel'
import { targetBanner } from 'config/constants/banner'
import { BannerDetail } from 'config/types/home'
import React, { useMemo, useRef, useState } from 'react'
import { useResponsiveLayout } from 'state/app/hooks'
import styled, { css } from 'styled-components'
import { Icons } from 'svgs'
import { EffectFade } from 'swiper'
import 'swiper/css/effect-fade'
import { detectResponsiveBreakpoint } from 'utils/styled'

const StyledWrapCarousel = styled(Box)`
  position: relative;
  height: 100%;
  max-width: 100%;
  overflow: hidden;
`

const getShadownSide = ({ side, theme }) => {
  switch (side) {
    case 'top':
      return css`
        top: 0;
        left: 0;
        transform: scaleY(-1);
        width: 100%;
        height: 45px;
        background: linear-gradient(180deg, rgba(22, 22, 26, 0), rgba(22, 22, 26, 0.8) 100%);

        ${theme.mediaQueries.xxl} {
          height: 90px;
        }
      `
    case 'left':
      return css`
        top: 0;
        left: 0;
        width: 60px;
        height: 100%;
        background-image: linear-gradient(270deg, rgba(22, 22, 26, 0), ${theme.colors.background});

        ${theme.mediaQueries.xxl} {
          width: 86px;
        }
      `
    case 'right':
      return css`
        top: 0;
        right: 0;
        width: 60px;
        height: 100%;
        background-image: linear-gradient(90deg, rgba(22, 22, 26, 0), ${theme.colors.background});

        ${theme.mediaQueries.xxl} {
          width: 86px;
        }
      `
    case 'bottom':
      return css`
        bottom: 0;
        width: 100%;
        height: 60px;
        background: linear-gradient(180deg, rgba(22, 22, 26, 0), ${theme.colors.background} 100%);

        ${theme.mediaQueries.xxl} {
          height: 86px;
        }
      `

    default:
      return css``
  }
}

export const BoxShadowSide = styled(Box)<{ side: 'top' | 'left' | 'right' | 'bottom' }>`
  position: absolute;
  pointer-events: none;
  z-index: 2;
  ${getShadownSide};

  display: none;

  ${({ theme }) => theme.mediaQueries.lg} {
    display: block;
  }
`

const HomeCarousel: React.FC<{ banners: BannerDetail[] }> = ({ banners }) => {
  const controlledRef = useRef(null)
  const [activeSlide, setActiveSlide] = useState(0)

  const responsive = useResponsiveLayout()

  const slidesBanner = useMemo(() => {
    return banners.map((banner, index) => {
      const responsiveConfig = responsive([
        [576, 240, banner.mobileImageSrc],
        [576, 240, banner.mobileImageSrc],
        [1920, 600, banner.imageSrc],
      ])

      const [width, height, src] = detectResponsiveBreakpoint(responsiveConfig)

      return {
        key: index,
        content: (
          <StyledCarouseLinkItem
            alt={banner.url}
            external={banner.target === targetBanner[2]}
            src={src}
            href={banner.url}
            width={width}
            height={height}
            original
          />
        ),
      }
    })
  }, [banners, responsive])

  const pbBanner = ['57.97%', '', '31.25%']

  return (
    <StyledWrapCarousel>
      <StyledCarousel
        pb={responsive(pbBanner)}
        effect="fade"
        loop
        autoplay={{
          delay: 5000,
        }}
        slides={slidesBanner}
        modules={[EffectFade]}
        onSwiper={(swiper) => {
          controlledRef.current = swiper
        }}
        pagination
        onActiveSlideChange={(index) => setActiveSlide(index)}
      />

      <StyledArrowPaging display={['none', 'none', 'none', 'flex']}>
        <Flex alignItems="center">
          <ArrowLeftContainer
            width="60px"
            height="80px"
            onClick={() => {
              controlledRef.current.slideToLoop(activeSlide - 1)
            }}
          >
            <Icons.ArrowLeftMainBanner width="60px" height="80px" />
          </ArrowLeftContainer>

          <ArrowRigthContainer
            width="60px"
            height="80px"
            onClick={() => {
              controlledRef.current.slideToLoop(activeSlide + 1)
            }}
          >
            <Icons.ArrowRightMainBanner width="60px" height="80px" />
          </ArrowRigthContainer>
        </Flex>
      </StyledArrowPaging>
    </StyledWrapCarousel>
  )
}

const StyledCarousel = styled(Carousel)`
  ${StyledPaginationContainer} {
    display: flex;
    width: fit-content;
    position: absolute;
    bottom: 2%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;

    .item-pagination-active {
      background: ${({ theme }) => theme.colors.primary};
    }
  }
`

const StyledArrowPaging = styled(Box)`
  position: absolute;
  width: 100%;
  z-index: 3;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const ArrowLeftContainer = styled(Box)`
  position: absolute;
  left: 2%;
  cursor: pointer;
  opacity: 0.4;

  &:hover {
    svg {
      path {
        fill: ${({ theme }) => theme.colors.text} !important;
        color: ${({ theme }) => theme.colors.text} !important;
        opacity: 1;
      }
    }
  }
`

const ArrowRigthContainer = styled(Box)`
  position: absolute;
  right: 3.5%;
  cursor: pointer;
  opacity: 0.4;

  &:hover {
    svg {
      path {
        fill: ${({ theme }) => theme.colors.text} !important;
        color: ${({ theme }) => theme.colors.text} !important;
        opacity: 1;
      }
    }
  }
`

const StyledCarouseLinkItem = styled(CarouseLinkItem)`
  img {
    border-radius: 24px !important;
  }
`
export default HomeCarousel
