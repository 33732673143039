import { RowMiddle } from 'layout/Components/Row'
import styled from 'styled-components'
import { colors } from 'theme/colors'
import { BoxProps } from 'UIKit/Box/types'
import Image from 'UIKit/Image'
import TransText from 'UIKit/Text/TransText'

const CommitmentsInfomation: React.FC<
  BoxProps & {
    iconSrc?: string
    content: string
  }
> = ({ iconSrc, content, ...props }) => {
  return (
    <RowMiddle pr="16px" mr="16px" borderRight={`1px solid ${colors.text}`} {...props} height="20px">
      {iconSrc && <StyledImage src={iconSrc} width={14} height={17} />}
      <TransText
        ml="4px"
        fontSize={['12px', '', '', '14px']}
        fontWeight={500}
        letterSpacing="-0.28px"
        lineHeight="normal"
        whiteSpace="nowrap"
      >
        {content}
      </TransText>
    </RowMiddle>
  )
}

const StyledImage = styled(Image)`
  min-width: 14px;
`

export default CommitmentsInfomation
