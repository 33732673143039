import Box from 'UIKit/Box/Box'
import Grid from 'UIKit/Box/Grid'
import Button from 'UIKit/Button'
import { InputContainer, InputLabel, StyledInput } from 'UIKit/Input/styled'
import { WALLET_INFO } from 'config/constants/wallet'
import { WalletInfo } from 'config/types/wallet'
import useRecentWallet from 'hooks/useRecentWallet'
import { useRouter } from 'hooks/useRouter'
import Column from 'layout/Components/Column'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { Icons } from 'svgs'
import { colors } from 'theme/colors'
import hunnyKeyframes from 'theme/keyframes'
import { AuthModalPageEnums } from 'views/Authentication/types'
import BonusCountdown from '../BonusCountdown'
import ReferralCode from '../ReferralCode'
import { ConditionAcccess } from '../Template'
import CheckCondition from '../Traditional/CheckCondition'
import WalletCard from '../WalletCard'

const WalletList: React.FC<{
  isShowMore: boolean
  setShowMore: (more: boolean) => void
  setSelectedWallet: (wallet: WalletInfo) => void
  page: AuthModalPageEnums
  onSignUpPreparePayload?: (data: { promoCode?: string }) => void
  disabledCheckCondition?: boolean
}> = ({ isShowMore, setShowMore, setSelectedWallet, page, onSignUpPreparePayload, disabledCheckCondition = false }) => {
  const { recentWallet } = useRecentWallet()
  const sortedWallets = useMemo(
    () =>
      WALLET_INFO.sort((firstWallet, secondWallet) => {
        if (firstWallet.name === recentWallet) return -1
        if (secondWallet.name === recentWallet) return 1
        if (firstWallet.installed && secondWallet.installed) return firstWallet.name.localeCompare(secondWallet.name)
        if (firstWallet.installed) return -1
        if (secondWallet.installed) return 1
        return 0
      }),
    [],
  )

  const displayWallets = isShowMore ? sortedWallets : sortedWallets.slice(0, 10)
  const [isConfirmed, setIsConfirmed] = useState(true)
  const { t } = useTranslation()

  const router = useRouter()
  const { bonus: bonusCodeUrl } = router.query
  const [promoCode, setPromoCode] = useState(bonusCodeUrl)

  return (
    <Wrapper $more={isShowMore}>
      <WrapListWallet confirmed={isConfirmed}>
        <WrapGridWallet $more={isShowMore}>
          {displayWallets.map((wallet) => (
            <WalletCard
              id="wallet-select"
              key={wallet.name}
              isRecentWallet={wallet.name === recentWallet}
              isDetected={wallet.installed}
              wallet={wallet}
              onSelect={() => {
                setSelectedWallet(wallet)
              }}
            />
          ))}
        </WrapGridWallet>
      </WrapListWallet>
      <StyledToggleButton id={isShowMore ? 'wallet-less' : 'wallet-more'} onClick={() => setShowMore(!isShowMore)}>
        {t(isShowMore ? 'Show less' : 'Show more')}
        <Box>
          <StyledIconWrapper $more={isShowMore} width="18px" height="18px">
            <Icons.ArrowDownIconAlt width="18px" height="18px" fill={colors.textSubtle} />
          </StyledIconWrapper>
        </Box>
      </StyledToggleButton>
      <ReferralCode mt="36px" />
      {page === AuthModalPageEnums.SIGN_UP ? (
        <StyledPromoCodeContainer mt={['20px', '', '24px']}>
          <StyledInputPromoCodeLabel>{t('Enter Promo Code (Optional)')}</StyledInputPromoCodeLabel>

          <InputContainer mt="4px" height="54px" padding="0px 12px">
            <Icons.TicketIcon size={24} />
            <StyledPromoCodeInput
              tabIndex={1}
              name="promo-code"
              placeholder={t('Promo Code')}
              onValueChanged={(value) => {
                setPromoCode(value)
                if (onSignUpPreparePayload) onSignUpPreparePayload({ promoCode: value })
              }}
              value={promoCode}
            />
          </InputContainer>

          <Box mt={['20px', '', '24px']}>
            <CheckCondition value={Boolean(isConfirmed)} onValueChanged={setIsConfirmed} />
          </Box>

          <BonusCountdown onExpired={() => {}} display={['block', '', '', 'none']} mt="36px" />
        </StyledPromoCodeContainer>
      ) : (
        !disabledCheckCondition && <ConditionAcccess />
      )}
    </Wrapper>
  )
}

const StyledInputPromoCodeLabel = styled(InputLabel)`
  color: ${({ theme: { colors } }) => colors.textSubtle} !important;
`

const StyledPromoCodeContainer = styled(Box)`
  &:hover {
    ${StyledInputPromoCodeLabel} {
      color: ${({ theme: { colors } }) => colors.text} !important;
    }
  }
`

const Wrapper = styled(Column)<{ $more: boolean }>`
  max-height: 100%;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const WrapListWallet = styled(Column)<{
  confirmed?: boolean
}>`
  overflow: hidden;
  box-sizing: border-box;
  padding-bottom: 10px;
  position: relative;

  ${({ confirmed }) =>
    !confirmed &&
    css`
      opacity: 0.4;

      &:after {
        content: ' ';
        position: absolute;
        top: 0;
        left: 0;
        user-select: none;
        width: 100%;
        height: 100%;
      }
    `};
`

const WrapGridWallet = styled(Grid).attrs({ gridTemplateColumns: ['1fr 1fr'] })<{
  $more: boolean
}>`
  animation: ${({ $more }) => ($more ? hunnyKeyframes.slideTopToBottom : hunnyKeyframes.slideBottomToTop)} 0.5s ease-out
    forwards;
  margin-right: -12px;
  padding-right: 12px;
  overflow-y: scroll;
  grid-column-gap: 8px;
  grid-row-gap: 12px;
`

const StyledToggleButton = styled(Button).attrs({ variant: 'text' })`
  display: flex;
  align-items: center;
  justify-content: center;

  height: auto !important;

  font-size: 12px;
  font-weight: 400;

  margin: 0px auto;
  margin-top: 4px;

  color: ${({ theme: { colors } }) => colors.textSubtle};
`

const StyledIconWrapper = styled(Box)<{ $more: boolean }>`
  transform: ${({ $more }) => ($more ? 'rotate(180deg)' : '')};
  transform-origin: center center;
  transition: 0.2s transform linear;
`

const StyledPromoCodeInput = styled(StyledInput)`
  height: auto !important;
  color: ${({ theme: { colors } }) => colors.text};
  margin-left: 8px;
`

export default WalletList
