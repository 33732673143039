import { ChainIdEnum } from 'config/constants/network'
import tokens, { HUSD_TOKEN, USD_CURRENCY } from 'config/constants/tokens'
import { UserBonus } from 'config/types/bonus/userBonus'
import { useMemo } from 'react'
import { useValidatePlayToken } from 'state/app/hooks'
import { useTokenSelected } from 'state/session/hooks'
import { isFreeSpinBonusFiatReward } from 'utils/bonus'
import { isFiatCurrencyV2 } from 'utils/token'

export const useFreeSpinPlayGameToken = (bonus: UserBonus) => {
  const selectedToken = useTokenSelected()
  const { isBlackList } = useValidatePlayToken(selectedToken)

  return useMemo(() => {
    const isFreeSpinBonusIsFiat = isFreeSpinBonusFiatReward(bonus)
    const isFreeSpinBonusIsUsd = (bonus as any)?.freeSpinCurrency === USD_CURRENCY.code
    if (isFreeSpinBonusIsUsd) {
      if (isFiatCurrencyV2(selectedToken)) {
        return HUSD_TOKEN
      }
      if (isBlackList) return HUSD_TOKEN

      return selectedToken
    }
    if (isFreeSpinBonusIsFiat) {
      const freeSpinBonusFiat = tokens[ChainIdEnum.FIAT][(bonus as any).freeSpinCurrency]
      if (freeSpinBonusFiat.code !== selectedToken.code) {
        return freeSpinBonusFiat
      }

      return selectedToken
    }
  }, [bonus, selectedToken, isBlackList])
}
