import Box from 'UIKit/Box/Box'
import Flex from 'UIKit/Box/Flex'
import CopyButton from 'UIKit/Button/CopyButton'
import { InputContainer, InputLabel } from 'UIKit/Input/styled'
import Skeleton from 'UIKit/Skeleton'
import Text from 'UIKit/Text'
import { ChainIdEnum } from 'config/constants/network'
import { Token } from 'config/types'
import useDebounceCallback from 'hooks/useDebounceCallback'
import { useRequest } from 'hooks/useRequest'
import Column from 'layout/Components/Column'
import QRCode from 'qrcode.react'
import { useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import PaymentService from 'services/PaymentService'
import { useAppSelector } from 'state'
import styled from 'styled-components'
import { Icons } from 'svgs'
import theme from 'theme'
import { colors } from 'theme/colors'
import { formatToApiNetworkField } from 'utils'
import { isDisableSubscribeDeposit, isTonChain, isXrpChain } from 'utils/network'

interface DepositByQRCodeProps {
  depositToken: Token
}

const DepositByQRCode: React.FC<DepositByQRCodeProps> = ({ depositToken }) => {
  const [address, setAddress] = useState<string>('')
  const [minDepositAmount, setMinDepositAmount] = useState<string>('')
  const [isFetchingDepositInfo, setIsFetchingDepositInfo] = useState<boolean>(false)
  const debounce = useDebounceCallback()
  const { t } = useTranslation()

  const { execute } = useRequest()

  useEffect(() => {
    if (!isDisableSubscribeDeposit(depositToken.network)) {
      debounce(() => PaymentService.subscribeDeposit(depositToken), 10000)
    }
  }, [depositToken])

  useEffect(() => {
    if (depositToken) {
      const fetchDepositAddress = async () => {
        setIsFetchingDepositInfo(true)

        const response = await execute(
          PaymentService.traditionalDeposit({
            currency: depositToken.code,
            network: formatToApiNetworkField(ChainIdEnum[depositToken.network]),
          }),
        )

        if (response) {
          setAddress(response.data?.address)
          setMinDepositAmount(response.data?.minAmount)
        }
        setIsFetchingDepositInfo(false)
      }

      fetchDepositAddress()
    }
  }, [depositToken])

  const tokenInfoes = useAppSelector((state) => state.app.tokenInfoes)

  const [addressReceiveFund, qrCode, memo] = useMemo(() => {
    if (!depositToken) return ['', '', '']
    if (isTonChain(depositToken.network) || isXrpChain(depositToken.network)) {
      const spender = tokenInfoes.find(
        (tokenInfo) => tokenInfo.network === depositToken.network && tokenInfo.token === depositToken.code,
      )?.contractAddress

      return [spender, spender, address]
    }

    return [address, address, '']
  }, [depositToken, address, tokenInfoes])

  return (
    <Box>
      <Column my="20px">
        <InputLabel color="textSubtle" mb="8px">
          <Trans>Deposit address</Trans>
        </InputLabel>
        <InputContainer p="15px">
          <Flex alignItems="center" justifyContent="space-between" width="100%">
            <StyledDepositAddress lineHeight="normal" fontSize={['12px', '12px', '14px']}>
              {addressReceiveFund}
            </StyledDepositAddress>
            <CopyButton text={addressReceiveFund} />
          </Flex>
        </InputContainer>
      </Column>

      {memo && (
        <Column>
          <InputLabel color="textSubtle" mb="8px">
            <Trans>Destination Tag</Trans>
          </InputLabel>
          <InputContainer p="15px">
            <Flex minHeight="24px" alignItems="center" justifyContent="space-between" width="100%">
              <StyledDepositAddress fontSize={['12px', '12px', '14px']}>{memo}</StyledDepositAddress>
              <CopyButton text={memo} />
            </Flex>
          </InputContainer>

          <Flex
            alignItems="center"
            border={`1px solid ${colors.warning}`}
            padding="4px 6px"
            borderRadius={theme.radii.small}
            my="8px"
          >
            <Box mr="6px">
              <Icons.WarningIcon width="20px" fill={colors.warning} />
            </Box>

            <Text bold fontSize="10px" color="warning" textAlign="left" lineHeight="14px">
              {t('Important! If you make a transaction without a Destination tag, your funds will be lost!')}
            </Text>
          </Flex>
        </Column>
      )}

      <Box
        m="20px auto"
        p="12px"
        height="144px"
        width="144px"
        background={theme.colors.text}
        borderRadius={theme.radii.small}
      >
        {!isFetchingDepositInfo && <StyledQRCode value={qrCode} renderAs="svg" />}
        {isFetchingDepositInfo && <StyledQRCodeLoader />}
      </Box>

      <Flex
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        background="rgba(255, 255, 255, 0.04)"
        borderRadius={theme.radii.small}
        height="48px"
        padding="12px"
      >
        <Text fontSize={['12px', '12px', '14px']} color="textSubtle" textTransform="capitalize">
          <Trans>minimum deposit</Trans>
        </Text>
        <Box>
          <Text fontSize={['12px', '12px', '14px']} as="span">
            {minDepositAmount}
          </Text>
          <Text fontSize={['12px', '12px', '14px']} as="span" ml="5px">
            {depositToken?.name}
          </Text>
        </Box>
      </Flex>

      <Box width="100%" mt="30px">
        <Text fontSize="12px" textAlign="center" fontStyle="italic">
          *<Trans>Send only {{ tokenName: depositToken?.name }} to this deposit address</Trans>
        </Text>
      </Box>
    </Box>
  )
}

const StyledDepositAddress = styled(Text)`
  max-width: 90%;
  word-break: break-word;
`
const StyledQRCodeLoader = styled(Skeleton)`
  width: 120px;
  height: 120px;

  padding: 12px;
`

const StyledQRCode = styled(QRCode)`
  width: 120px !important;
  height: 120px !important;
`

export default DepositByQRCode
