import BigNumber from 'bignumber.js'
import { Icons } from 'svgs'
import { RefUserProfile } from './profile'
import { Token } from '.'

export interface TipDetails {
  id: number
  message?: string
  createTime?: number
  receiver?: RefUserProfile
  sender?: RefUserProfile
  currency: string
  network: string
  amount: string
}

export interface LockAmountTipInfo {
  lockBonusAmount: string
  voucherId: number
  depositRolloverList: DepositRolloverInfo[]
}

export enum DepositFiatPaymentTypeEnums {
  OnlineBanking = 3,
  Qr = 4,
  EWallet = 5,
  VirtualAccount = 6,
  LocalBankTransfer = 7,
}

export enum WithdrawFiatPaymentTypeEnums {
  OnlineBanking = 1,
}

export const FiatMethodCategory: { methods: any[]; icon: string; title: string }[] = [
  {
    methods: [DepositFiatPaymentTypeEnums.LocalBankTransfer],
    icon: Icons.HouseIcon,
    title: 'Local Bank Transfer',
  },
  {
    methods: [DepositFiatPaymentTypeEnums.Qr],
    icon: Icons.QrCodeIcon,
    title: 'QR Payment (QR)',
  },
  {
    methods: [DepositFiatPaymentTypeEnums.EWallet],
    icon: Icons.EwalletIcon,
    title: 'e-Wallet (QR)',
  },
  {
    methods: [DepositFiatPaymentTypeEnums.VirtualAccount],
    icon: Icons.GeoPointIcon,
    title: 'Virtual Account (VA)',
  },

  {
    methods: [DepositFiatPaymentTypeEnums.OnlineBanking, WithdrawFiatPaymentTypeEnums.OnlineBanking],
    icon: Icons.ComputerIcon,
    title: 'Online Banking (OB)',
  },
]

export interface FiatPaymentMethod {
  thumbnail: string
  name: string
  code: string
  currency: string
  isActive: boolean
  minAmount: string
  maxAmount: string
}

export interface DepositFiatPaymentMethod extends FiatPaymentMethod {
  type: DepositFiatPaymentTypeEnums
}

export interface WithdrawFiatPaymentMethod extends FiatPaymentMethod {
  type: WithdrawFiatPaymentTypeEnums
  feeAmount: string
  feePercent: string
}

export interface MinWithdrawTokenAmounts {
  [key: string]: string
}

export interface DepositRolloverBase {
  bonusInfo?: {
    bonusName?: string
    bonusId?: number
  }
}

export interface DepositRolloverInfo extends DepositRolloverBase {
  token: Token
  lockAmount: BigNumber
  currentUnlockAmount: BigNumber
  createTime: number
}

export interface WithdrawInfo {
  withdrawFee: string
  lockBonusAmount: string
  voucherId: number
  depositRolloverList: DepositRolloverInfo[]
}
