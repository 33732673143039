import { Icons } from 'svgs'
import Text from 'UIKit/Text'
import TransText from 'UIKit/Text/TransText'
import MethodsSuport from 'views/Payment/components/PaymentCommitments/MethodsSuport'
import InformationDetails from '../components/InformationDetails'
import HighlightText from '../components/HighlightText'
import Property from '../components/Property'

const PaymentRules = () => {
  return (
    <>
      <InformationDetails
        icon={<Icons.DepositIcon width="24px" />}
        title="Instant Deposits & Withdrawals – No Restrictions"
        mb="32px"
      >
        <HighlightText translateKey="paymentRules" mt="16px" />
        <TransText
          my="8px"
          fontSize={['12px', '', '', '14px']}
          fontWeight={400}
          color="textSubtle"
          lineHeight={['15px', '', '', '18px']}
        >
          Why you’ll love it:
        </TransText>
        <Property src="/images/commitments/lightning.png" mt="0px">
          Instant Deposits & Withdrawals – Access your funds immediately.
        </Property>
        <Property src="/images/commitments/restrict.png">
          No wagering requirements on deposits – Use your funds freely, without limits.
        </Property>
        <MethodsSuport mt="16px" />
      </InformationDetails>

      <InformationDetails icon={<Icons.SuportIcon width="24px" />} title="24/7 Customer Service" mb="32px">
        <HighlightText translateKey="customerService" mt="16px">
          <Text as="span">🙋‍♂️ </Text>
        </HighlightText>
      </InformationDetails>
    </>
  )
}

export default PaymentRules
