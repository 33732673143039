import { Trans } from 'react-i18next'
import Box from 'UIKit/Box/Box'
import Flex from 'UIKit/Box/Flex'
import { BoxProps } from 'UIKit/Box/types'
import Image from 'UIKit/Image'
import Text from 'UIKit/Text'

const networksImage = [
  '/images/network/btc.png',
  '/images/network/eth.png',
  '/images/network/bsc.png',
  '/images/network/sol.png',
  '/images/network/xrp.png',
  '/images/token/usdt.png',
  '/images/token/usdc.png',
  '/images/network/ton.png',
  '/images/network/avax.png',
  '/images/token/ltc.png',
  '/images/network/trx.png',
  '/images/network/matic.png',
  '/images/network/ftm.png',
  '/images/network/op.png',
  '/images/network/arb.png',
  '/images/token/thb.png',
  '/images/token/php.png',
  '/images/token/idr.png',
  '/images/token/myr.png',
]
const MethodsSuport: React.FC<BoxProps> = ({ ...props }) => {
  return (
    <Box {...props}>
      <Text fontWeight="400" fontSize={['12px', '', '14px']} lineHeight={['15px', '', '18px']} color="textSubtle">
        <Trans>Supported Payment Methods</Trans>:
      </Text>
      <Flex flexWrap="wrap" mt="8px">
        {networksImage?.map((item) => {
          return <Image key={item} src={item} width={20} height={20} marginRight="4px" marginBottom="6px" />
        })}
      </Flex>
    </Box>
  )
}

export default MethodsSuport
