import { RowMiddle } from 'layout/Components/Row'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import Box from 'UIKit/Box/Box'
import Text from 'UIKit/Text'
import CommitmentsInfomation from './CommitmentsInfomation'

export type InformationType = {
  iconSrc: string
  content: string
}

type InformationSwitcherProps = {
  informations: InformationType[]
}

const InformationSwitcher: React.FC<InformationSwitcherProps> = ({ informations }) => {
  const [currentIndex, setCurrentContentIndex] = useState(0)
  const [animation, setAnimation] = useState('')

  useEffect(() => {
    const interval = setInterval(() => {
      setAnimation('slide-up')
      setTimeout(() => {
        setCurrentContentIndex((prev) => (prev + 1) % informations.length)
        setAnimation('slide-down')
      }, 300)
    }, 3000)

    return () => clearInterval(interval)
  }, [])

  return (
    <StyledContainer>
      <Box className={`content ${animation}`}>
        <StyledItem content={informations[currentIndex].content} />
      </Box>
    </StyledContainer>
  )
}

const StyledItem = styled(CommitmentsInfomation)`
  ${Text} {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -0.24px;
    line-height: normal;
  }
  border-right: none;
  padding-right: 0px;
  justify-content: flex-end;
  margin-right: 4px;
`

const StyledContainer = styled(RowMiddle)`
  width: 120px;
  height: 50px;
  overflow: hidden;
  position: relative;
  justify-content: flex-end;

  .content {
    font-size: 20px;
    position: absolute;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
  }

  .slide-up {
    animation-name: slideUp;
  }

  .slide-down {
    animation-name: slideDown;
  }

  @keyframes slideUp {
    from {
      transform: translateY(0);
      opacity: 1;
    }
    to {
      transform: translateY(-100%);
      opacity: 0;
    }
  }

  @keyframes slideDown {
    from {
      transform: translateY(100%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
`

export default InformationSwitcher
