import useMatchBreakpoints from 'hooks/useMatchBreakpoints'
import { RowBetween, RowMiddle } from 'layout/Components/Row'
import styled from 'styled-components'
import { Icons } from 'svgs'
import Box from 'UIKit/Box/Box'
import { BoxProps } from 'UIKit/Box/types'
import Button from 'UIKit/Button'
import TransText from 'UIKit/Text/TransText'
import useCommitmentsModal from '../CommitmentsModal/useCommitmentsModal'
import CommitmentsInfomation from './CommitmentsInfomation'
import InformationSwitcher, { InformationType } from './InformationSwitcher'

const INFORMATIONS: InformationType[] = [
  { content: 'Secure privacy', iconSrc: '/images/lock-icon.png' },
  { content: '24/7 support', iconSrc: '/images/chat-3d-icon.png' },
  { content: 'Huge bonus', iconSrc: '/images/gift-box-3d.png' },
]

const CommitmentsBar: React.FC<BoxProps> = ({ ...props }) => {
  const { isMobile } = useMatchBreakpoints()
  const [onPresentCommitmentsModal] = useCommitmentsModal()

  return (
    <StyledCommitments
      height={['24px', '', '', '32px']}
      p={['0px 8px !important', '', '', '0px 16px !important']}
      onClick={onPresentCommitmentsModal}
      {...props}
    >
      <RowBetween>
        <RowMiddle pr={['0px', '', '', '16px']} mr={['0px', '', '', '16px']} height="24px">
          <Box width={['20px', '', '', '24px']} height={['20px', '', '', '24px']}>
            <Icons.ProtectIcon width="100%" height="100%" />
          </Box>
          <TransText
            ml="4px"
            fontSize={['12px', '', '', '14px']}
            letterSpacing="-0.24px"
            lineHeight="normal"
            fontWeight={600}
          >
            HunnyPlay's Commitments
          </TransText>
        </RowMiddle>

        <RowMiddle>
          {isMobile ? (
            <InformationSwitcher informations={INFORMATIONS} />
          ) : (
            <>
              <CommitmentsInfomation iconSrc="/images/lock-icon.png" content="Secure privacy" />
              <CommitmentsInfomation iconSrc="/images/chat-3d-icon.png" content="24/7 support" />
              <CommitmentsInfomation
                iconSrc="/images/gift-box-3d.png"
                content="Huge bonus"
                border="none"
                marginRight="5px"
              />
            </>
          )}

          <Icons.ChevronRightIcon width="6px" height="10px" fill="#fff" />
        </RowMiddle>
      </RowBetween>
    </StyledCommitments>
  )
}

const StyledCommitments = styled(Button)`
  background: #12b76a;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
  &:hover {
    transition: none !important;
    transform: none !important;
  }
`

export default CommitmentsBar
