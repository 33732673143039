import aoaTokens from './aoa'
import aoaTestnetTokens from './aoa_testnet'
import avaxTokens from './avax'
import avaxTestnetTokens from './avax_testnet'
import bscTokens from './bsc'
import bscTestnetTokens from './bsc_testnet'
import btcTokens from './btc'
import btcTestnetTokens from './btc_testnet'
import celoTokens from './celo'
import celoTestnetTokens from './celo_testnet'
import croTokens from './cro'
import croTestnetTokens from './cro_testnet'
import ethTokens from './eth'
import ethTestTokens from './eth_testnet'
import fiatTokens from './fiat'
import ftmTokens from './ftm'
import ftmTestnetTokens from './ftm_testnet'
import fuseTokens from './fuse'
import fuseTestnetTokens from './fuse_tesnet'
import oneTokens from './hamony'
import oneTestnetTokens from './hamony_testnet'
import hecoTokens from './heco'
import hecoTestnetTokens from './heco_testnet'
import hpnTokens from './hpn'
import movrTokens from './movr'
import movrTestnetTokens from './movr_testnet'
import oecTokens from './okt'
import oecTestnetTokens from './okt_testnet'
import maticTokens from './polygon'
import maticTestnetTokens from './polygon_testnet'
import solTokens from './sol'
import solTestnetTokens from './sol_testnet'
import trxTokens from './trx'
import trxTestnetTokens from './trx_testnet'
import vlxTokens from './velas'
import vlxTestnetTokens from './velas_testnet'
import xdaiTokens from './xDai'
import xdaiTestnetTokens from './xDai_testnet'
import arbTokens from './arb'
import arbTestnetTokens from './arb_testnet'
import opTokens from './op'
import opTestnetTokens from './op_testnet'
import tonTokens from './ton'
import tonTestnetTokens from './ton_testnet'
import xrpTokens from './xrp'
import xrpTestnetTokens from './xrp_testnet'
import ltcTokens from './ltc'
import ltcTestnetTokens from './ltc_testnet'
import dogeTokens from './doge'
import dogeTestnetTokens from './doge_testnet'

import { ChainIdEnum } from '../network'

export const HUSD_TOKEN = hpnTokens.HUSD
export const USD_CURRENCY = hpnTokens.USD

export default {
  [ChainIdEnum.BNB]: bscTokens,
  [ChainIdEnum.ETH]: ethTokens,
  [ChainIdEnum.OEC]: oecTokens,
  [ChainIdEnum.AVAX]: avaxTokens,
  [ChainIdEnum.FTM]: ftmTokens,
  [ChainIdEnum.MATIC]: maticTokens,
  [ChainIdEnum.CELO]: celoTokens,
  [ChainIdEnum.ONE]: oneTokens,
  [ChainIdEnum.MOVR]: movrTokens,
  [ChainIdEnum.XDAI]: xdaiTokens,
  [ChainIdEnum.CRO]: croTokens,
  [ChainIdEnum.HECO]: hecoTokens,
  [ChainIdEnum.VLX]: vlxTokens,
  [ChainIdEnum.AOA]: aoaTokens,
  [ChainIdEnum.FUSE]: fuseTokens,
  [ChainIdEnum.SOL]: solTokens,
  [ChainIdEnum.BTC]: btcTokens,
  [ChainIdEnum.TRX]: trxTokens,
  [ChainIdEnum.ARB]: arbTokens,
  [ChainIdEnum.OP]: opTokens,
  [ChainIdEnum.TON]: tonTokens,
  [ChainIdEnum.XRP]: xrpTokens,
  [ChainIdEnum.LTC]: ltcTokens,
  [ChainIdEnum.DOGE]: dogeTokens,

  [ChainIdEnum.BNB_TESTNET]: bscTestnetTokens,
  [ChainIdEnum.OEC_TESTNET]: oecTestnetTokens,
  [ChainIdEnum.AVAX_TESTNET]: avaxTestnetTokens,
  [ChainIdEnum.FTM_TESTNET]: ftmTestnetTokens,
  [ChainIdEnum.MATIC_TESTNET]: maticTestnetTokens,
  [ChainIdEnum.CELO_TESTNET]: celoTestnetTokens,
  [ChainIdEnum.ONE_TESTNET]: oneTestnetTokens,
  [ChainIdEnum.MOVR_TESTNET]: movrTestnetTokens,
  [ChainIdEnum.XDAI_TESTNET]: xdaiTestnetTokens,
  [ChainIdEnum.CRO_TESTNET]: croTestnetTokens,
  [ChainIdEnum.HECO_TESTNET]: hecoTestnetTokens,
  [ChainIdEnum.VLX_TESTNET]: vlxTestnetTokens,
  [ChainIdEnum.AOA_TESTNET]: aoaTestnetTokens,
  [ChainIdEnum.FUSE_TESTNET]: fuseTestnetTokens,
  [ChainIdEnum.SOL_TESTNET]: solTestnetTokens,
  [ChainIdEnum.ETH_TESTNET]: ethTestTokens,
  [ChainIdEnum.BTC_TESTNET]: btcTestnetTokens,
  [ChainIdEnum.TRX_TESTNET]: trxTestnetTokens,
  [ChainIdEnum.ARB_TESTNET]: arbTestnetTokens,
  [ChainIdEnum.OP_TESTNET]: opTestnetTokens,
  [ChainIdEnum.TON_TESTNET]: tonTestnetTokens,
  [ChainIdEnum.XRP_TESTNET]: xrpTestnetTokens,
  [ChainIdEnum.LTC_TESTNET]: ltcTestnetTokens,
  [ChainIdEnum.DOGE_TESTNET]: dogeTestnetTokens,

  [ChainIdEnum.HPN]: hpnTokens,
  [ChainIdEnum.FIAT]: fiatTokens,
}
