import Box from 'UIKit/Box/Box'
import { FlexProps } from 'UIKit/Box/types'
import { CloseButtonModal, StyledButtonCloseModal } from 'UIKit/Modal/styled'
import Text from 'UIKit/Text'
import { Context } from 'contexts/Modals'
import React, { useContext } from 'react'
import { Trans } from 'react-i18next'
import styled from 'styled-components'

const ModalHeader: React.FC<{ wrapBackground?: string; title?: string } & FlexProps> = ({
  wrapBackground,
  title,
  children,
  color,
  ...props
}) => {
  const { onDismiss } = useContext(Context)

  return (
    <StyledContainer background={wrapBackground} className="wrap-header-container" minHeight="44px" my="8px" {...props}>
      {title ? (
        <Text color={color || 'white'} fontWeight={600}>
          <Trans>{title}</Trans>
        </Text>
      ) : (
        children
      )}
      <Box ml="auto">
        <CloseButtonModal onDismiss={() => onDismiss()} />
      </Box>
    </StyledContainer>
  )
}

ModalHeader.displayName = 'ModalHeader'

const StyledContainer = styled(Box)<FlexProps>`
  display: ${({ display }) => display || 'flex'};
  align-items: ${({ alignItems }) => alignItems || 'center'};

  justify-content: space-between;

  position: sticky;

  font-size: 14px;
  ${Text} {
    font-weight: 500;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    font-size: 17px;
  }

  ${StyledButtonCloseModal} {
    position: relative;
    top: 0 !important;
    right: 0 !important;
  }
`

export default ModalHeader
